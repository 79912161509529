import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader-state';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  private showStartTime: number = 0;
  private hideTimeoutId: any = null;
  private readonly MIN_DISPLAY_TIME = 400; // Tempo mínimo em milissegundos
  
  constructor() { }

  show() {
    // Limpar qualquer timeout pendente
    if (this.hideTimeoutId !== null) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }
    
    // Registrar o tempo de início
    this.showStartTime = new Date().getTime();
    
    // Mostrar o loader
    this.loaderSubject.next(<LoaderState>{ show: true });
  }
  
  hide() {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - this.showStartTime;
    
    // Se o tempo mínimo ainda não passou, agendar para esconder depois
    if (elapsedTime < this.MIN_DISPLAY_TIME) {
      const remainingTime = this.MIN_DISPLAY_TIME - elapsedTime;
      
      // Limpar qualquer timeout pendente
      if (this.hideTimeoutId !== null) {
        clearTimeout(this.hideTimeoutId);
      }
      
      // Agendar para esconder após o tempo restante
      this.hideTimeoutId = setTimeout(() => {
        this.loaderSubject.next(<LoaderState>{ show: false });
        this.hideTimeoutId = null;
      }, remainingTime);
    } else {
      // Se o tempo mínimo já passou, esconder imediatamente
      this.loaderSubject.next(<LoaderState>{ show: false });
    }
  }
}