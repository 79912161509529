import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { LoaderService } from './shared/interceptors/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  isAuthorizedSubscription: Subscription | undefined;
  isAuthorized = false;

  onChecksessionChanged: Subscription | undefined;
  routerSubscription: Subscription | undefined;
  loaderSubscription: Subscription | undefined;

  checksession = false;
  hash = '';
  isPageLoading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    console.log('AppComponent STARTING');
    this.isAuthorized = this.authService.isAuthenticated();
  }

  ngOnInit() {
    // Inicializar o estado do loader como falso
    this.isPageLoading = false;
    
    // Inscrever-se nos eventos de navegação do Router
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Quando a navegação começa, mostrar o loader
        this.loaderService.show();
        // Usar setTimeout para adiar a atualização do estado
        setTimeout(() => {
          this.isPageLoading = true;
        });
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        // Quando a navegação termina (com sucesso, cancelamento ou erro), esconder o loader
        this.loaderService.hide();
        // Usar setTimeout para adiar a atualização do estado
        setTimeout(() => {
          this.isPageLoading = false;
        });
      }
    });

    // Inscrever-se no estado do loader para sincronizar o loader de página
    this.loaderSubscription = this.loaderService.loaderState.subscribe(state => {
      // Usar setTimeout para adiar a atualização do estado
      setTimeout(() => {
        this.isPageLoading = state.show;
      });
    });
  }

  ngOnDestroy(): void {
    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
    
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  login() {
    console.log('start login');
  }

  refreshSession() {

  }

  logout() {

  }
}
