import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';

import {
GenericCollectionResponseAlbumFotosEntity,
AlbumFotosEntity,
FotoVO,
TemplateDownloadVO,
UsuarioSistemaBase,
EstabelecimentoBase,
RoteiroAlvoProdutoVO,
ArquivoVO,
TipoFotoEnum,
StatusAvaliacaoEnum,
ResultadoAvaliacaoVO,
EnderecoVO,
ProdutoFamiliaBase,
ProdutoBase,
CategoriaProdutoBase,
SubCategoriaProdutoBase,
MarcaBase,
SegmentoProdutoBase,
ListarAlbumsPorUsuarioRequest,
ListarFotosRequest,
FotosRemovidasEntity,
AlbumFotosFilaEntity,
StatusFilaEnum,
AlbumFotoFilaRequest,
DownloadResponse,
ListAlbumRequest,
PaginacaoDTO,
SalvarAvaliacaoFotoTOOQRequest,
RespostaPromotorAppFotoRequest,
SalvarAvaliacaoFotoRequest,
HistoricoAvaliacaoFotosDTO,
MensagemAvaliacaoFotoVO,
AlbumFotosModeloDTO,
UploadFileDTO,
AlbumFotosRecorrenteEntity,
FiltroAlbumFotosVo,
DayOfWeek,
TipoRecorrenciaAlbumFotos,
GenericCollectionResponseAlbumFotosRecorrenteEntity,
AtualizacaoCadastralDadoColetadoEntity,
RoteiroDadosPesquisaFormsEntity,
TipoTarefaRoteiroEnum,
OrigemDadoColetadoEnum,
DadoConcorrente,
RespostaFormsVO,
EnumTipoItemPesquisaForms,
PickerPesquisaItemVO,
AtualizacaoCadastralPlanejadoEntity,
PesquisaFormsEntity,
ItemFormsVO,
TipoEntryFormsEnum,
NullableTipoFotoPesquisaFormsEnum,
PickerItemListActionFoto,
CadastrarAtualizacaoCadastralRequest,
PesquisaIdObrigatorio,
GenericCollectionResponseListaAtualizacaoCadastralDTO,
ListaAtualizacaoCadastralDTO,
ListarAtualizacaoCadastralRequest,
GenericCollectionResponseRoteiroDadosPesquisaFormsDTO,
RoteiroDadosPesquisaFormsDTO,
BandeiraEstabelecimentoEntity,
RedeEstabelecimentoBase,
GenericCollectionResponseBandeiraEstabelecimentoEntity,
GenericListRequest,
NullableStatusUsuarioEnum,
ListarPorUfsCidadesRequest,
OrcamentoRoteiroAutomaticoRequest,
BandeiraEstabelecimentoBase,
RedeEstabelecimentoEntity,
KeyValuePairStringString,
BandeiraERP,
LojaERP,
SubCanalBase,
UnidadeNegocioProprietarioBase,
CanalEstabelecimentoEntity,
StatusEstabelecimentoEntity,
SubstatusEstabelecimentoEntity,
MarcaEntity,
SegmentoProdutoEntity,
UsuarioSistemaEntity,
TelefoneVO,
DadosPessoaisVO,
StatusUsuarioEnum,
DocumentosVO,
PausaProgramadaVO,
HorarioDeTrabalho,
DispositivoAcessoSistemaEntity,
RegistroGeralVO,
CarteiraTrabalhoVO,
EstadoCivilEnum,
SexoEnum,
NullableTipoLocomocaoEnum,
ContaBancariaVO,
TipoContaBancariaEnum,
CartasRequest,
ModeloCartaEntity,
GenericCollectionResponseModeloCartaEntity,
ProdutoBaseComFoto,
ProdutoFamiliaEntity,
ProdutoEntity,
ValorMinMax,
CategoriaProdutoEntity,
GenericCollectionResponseCategoriaProdutoEntity,
CategoriaProdutoDTO,
ChatMensagemEntity,
StatusMensagem,
ConfiguracaoMobileEntity,
ConfiguracaoRegistroPontoVO,
ConfiguracaoSincronismoMobileVO,
RegistroSincronismoColecaoVO,
TipoSincronismoEnum,
ConfiguracaoWebDTO,
TelasEnum,
LinkBiVo,
ConfigEmailVo,
ConfiguracaoUsuarioPerfilAppDTO,
ConfiguracaoPontoApp,
ConfigDadosColetadosEntity,
TipoConfiguracaoEmpresaEnum,
EmpresaEntity,
GenericCollectionResponseEmpresaEntity,
UnidadeFederativaEntity,
GenericCollectionResponseEstabelecimentoEntity,
EstabelecimentoEntity,
ListarLojasPorUfRequest,
ListarEstabelecimentosPorBandeiraEnderecoRequest,
GeocodeResponse,
Result,
AddressComponent,
Geometry,
Bounds,
Location,
Viewport,
Northeast,
Southwest,
GenericCollectionResponseProdutoFamiliaEntity,
FeriadoEntity,
TipoFeriadoEnum,
TipoDataFeriadoEnum,
GenericCollectionResponseFeriadoEntity,
GenericCollectionResponseFeriadoDTO,
FeriadoDTO,
GenericCollectionResponseFormularioDinamicoEntity,
FormularioDinamicoEntity,
ModeloJsonVO,
TipoFormularioEnum,
Page,
Element,
FormularioDinamicoBase,
GenericRelatorioRequest,
GenericCollectionResponseHoraPorMarcaDTO,
HoraPorMarcaDTO,
HoraMarcaDTO,
LogDispositivoVO,
ListarLogsRequest,
GenericCollectionResponseLogDispositivoVO,
LogGeolocalizacaoVO,
LogDispositivoEntity,
LogGeolocalizacaoEntity,
GenericCollectionResponseMarcaEntity,
MarcaIdChatDTO,
ResumoRelatorioPromotor,
LiberarDispositivoRequest,
ResumoUsuarioLiberarDispositivoDTO,
UsuarioSistemaDTO,
DispositivoAcessoDesbloqueioDTO,
DispositivoDataVO,
SuperiorHabilitarDispositivoRequest,
NotificacaoEntity,
TipoNotificacaoEnum,
NotificacaoResponse,
NotificacaoMobileDTO,
TipoNotificacaoMobileEnum,
DataNotificacaoDTO,
NotificacaoLayoutEnum,
CalendarioNotificacaoVO,
NotificacaoCancelarListarRequest,
GenericCollectionResponseNotificacaoMobileCancelarDTO,
NotificacaoMobileCancelarDTO,
NotificacaoMobileEntity,
DataNotificacaoVO,
PagamentoExpressEntity,
ListarPagExpressRequest,
GenericCollectionResponsePagamentoExpressEntity,
GenericCollectionResponsePesquisaFormsEntity,
PesquisaFormsNomeDTO,
GenericCollectionResponseProdutoEntity,
UploadFotoProdutoDTO,
ProdutoEstabelecimentoEntity,
ComplementoVO,
VinculoFamiliaDTO,
MarcaHora,
Vinculado,
ProdutoEstabelecimentoDTO,
ProdutoVinculoVO,
ProdutosPorBandeiraRequest,
ProdutoEstabelecimentosDTO,
ProdutosPorZonaRequest,
ProfissaoEntity,
QuestionarioEntity,
ListarDadosColetadosRequest,
GenericCollectionResponseRoteiroDadosColetadosBase,
RoteiroDadosColetadosBase,
GenericCollectionResponseRedeEstabelecimentoEntity,
RegistroDePontoEntity,
TipoMarcacaoEnum,
RegistroDePontoValidatorEntity,
ListarRegistroDePontoRequest,
GenericCollectionResponseRegistroDePontoAgrupadoDTO,
RegistroDePontoAgrupadoDTO,
GenericCollectionResponseRegistroDePontoSinteticoDTO,
RegistroDePontoSinteticoDTO,
AbonarDescontarRequest,
CancelarAbonoRequest,
AbonarPeriodoRequest,
RegistroPontoUsuarioEntity,
ListarRegistrosPontoRequest,
GenericCollectionResponseRegistroPontoUsuarioEntity,
ListarRelatorioGeralRequest,
GenericCollectionResponseResumoAvaliacaoFotosDTO,
ResumoAvaliacaoFotosDTO,
GenericCollectionResponseResumoAvaliacaoFotosGeralDTO,
ResumoAvaliacaoFotosGeralDTO,
ExportarMatrizRequest,
ResumoMatrizDTO,
DiasSemana,
GenericCollectionResponseDescontoDTO,
DescontoDTO,
GenericCollectionResponseAtendimentoEstabelecimentoDTO,
AtendimentoEstabelecimentoDTO,
GenericCollectionResponseTempoExecucaoMarcaDTO,
TempoExecucaoMarcaDTO,
RelatorioTarefasPlanejadasRequest,
GenericCollectionResponseRelatorioPlanejadosDTO,
RelatorioPlanejadosDTO,
GenericCollectionResponseRelatorioAnaliseExecucaoDTO,
RelatorioAnaliseExecucaoDTO,
StatusRoteiroExecutadoEnum,
GenericCollectionResponseRelatorioAbastecimentoMarcasDTO,
RelatorioAbastecimentoMarcasDTO,
RelatorioFotoRegistroPontoAtestadoDTO,
GenericCollectionResponseHorasExtrasDTO,
HorasExtrasDTO,
ResumoTempoTrabalhadoDashDTO,
PromotoresOnlineDTO,
StatusPromotoresDashDTO,
RelatorioAbastecimentoMarcasDashDTO,
RelatorioAnaliseExecucaoDashDTO,
ListarTempoDeslocamentoRequest,
GenericCollectionResponseRelatorioTempoDeslocamentoDTO,
RelatorioTempoDeslocamentoDTO,
ResumoDashStatusRoteiroDTO,
GenericCollectionResponseResumoListarVersaoAtualDTO,
ResumoListarVersaoAtualDTO,
GenericCollectionResponseGenericAnaliseMarcasResponseDTO,
GenericAnaliseMarcasResponseDTO,
GenericAnaliseMarcasRequest,
GenericCollectionResponseResumoRelatorioGeralDTO,
ResumoRelatorioGeralDTO,
ListarRelatorioMarcasAtendidasRequest,
GenericCollectionResponseResumoRelatorioMarcasAtendidasDTO,
ResumoRelatorioMarcasAtendidasDTO,
GenericSemRoteiroRequest,
EnumSemRoteiroRequest,
GenericCollectionResponseGenericSemRoteiroResponse,
GenericSemRoteiroResponse,
GenericCollectionResponseResumoPerformanceMarcaDTO,
ResumoPerformanceMarcaDTO,
ListarAtrasadosRequest,
GenericCollectionResponseResumoAtrasadoDTO,
ResumoAtrasadoDTO,
ListarAtrasadosPeriodoRequest,
RoteiroInteligenteEstabelecimentoRequest,
PontoPartidaEnum,
GenericCollectionResponseOrcamentoRoteiroAutomaticoEntity,
OrcamentoRoteiroAutomaticoEntity,
VinculoLojaMarca,
ObterRoteirosAlterarEmMassaRequest,
AlterarRoteiroEmMassaRequest,
ImportarRoteiroRequest,
ListarCalendarioRoteiroPlanejadoRequest,
CalendarioRoteiroPlanejadoDTO,
RoteiroPlanejadoEntity,
RoteiroTarefaPlanejadaVO,
RoteiroAgendamentoBase,
RoteiroDadosComplementaresBase,
TipoRoteiroAgendamentoEnum,
RoteiroInteligenteRequest,
LatLgtEstabelecimentosDTO,
IncluirBulkTarefasRequest,
RoteiroDadosComplementaresGaleriaFotosVO,
RoteiroDadosComplementaresPesquisaDinamicaIdFormularioVO,
RoteiroDadosComplementaresPesquisaPrecoVO,
RoteiroDadosComplementaresPontoPromocionalVO,
RoteiroDadosComplementaresPesquisaConcorrenteVO,
RoteiroDadosComplementaresPontoExtraVO,
RoteiroDadosComplementaresMPDVVO,
RoteiroDadosComplementaresPesquisaFormsVO,
RoteiroDadosComplementaresShareGondolaVO,
TipoFotoGaleriaVO,
PesquisaFormsDadoComplementarVO,
TipoCategoriaVO,
ProdutoConcorrente,
ProdutoObrigatorioVO,
EnumTipoPesquisaFrente,
RoteiroExecutadoEntity,
RoteiroTarefaExecutadaVO,
RoteiroExecutadoDadosVisitaVO,
TempoExecucaoMarcaVO,
StatusTarefaRoteiroExecutadoEnum,
RegistroNaoVistaVO,
OcorrenciaAtendimentoMarcaEnum,
IFormFile,
GenericCollectionResponseRelatorioRupEProdDisponivelDTO,
RelatorioRupEProdDisponivelDTO,
FinalizarRoteiroRequest,
NullableTipoTarefaRoteiroEnum,
GenericCollectionResponseFinalizarRoteirosResponse,
FinalizarRoteirosResponse,
TransferenciaRoteiroRequest,
MudancaUsuarioRequest,
RelatorioEpiRequest,
EnumTipoEPI,
GenericCollectionResponseRelatorioEPIDTO,
RelatorioEPIDTO,
RoteiroPlanejadoAutomaticoEntity,
RoteirosAutomaticos,
FuturoPlanejadoVO,
OrcamentoRAutomaticoRequest,
LatLngRoteiroAutomaticoDTO,
GenericCollectionResponseSegmentoProdutoEntity,
SemVendasPlanejadoEntity,
SvLojaPlanejada,
SvRede,
SvFamiliaPlanejada,
SvProdutoPlanejado,
SemVendasExecutadoEntity,
SvLojaExecutada,
SvFamiliaExecutada,
SvProdutoExecutado,
TipoUnidadeContagemProdutoEnum,
GenericCollectionResponseSemVendasListarDTO,
SemVendasListarDTO,
SubCanalEntity,
GenericCollectionResponseSubCanalEntity,
SubCategoriaProdutoEntity,
GenericCollectionResponseSubCategoriaProdutoEntity,
SubCategoriaProdutoDTO,
SubirFotoRequest,
TimelineDTO,
ITimelineDetalhes,
TimeLineRequest,
TimeLineBateriaDTO,
TimeLineRoteiroDTO,
TimeLineLocalizacaoDTO,
TimeLineDadosDTO,
TimeLineListarDTO,
UnidadeNegocioProprietarioEntity,
GenericCollectionResponseUnidadeNegocioProprietarioEntity,
UsuarioCadastroEntity,
FrequenciaTrabalho,
TipoLocomocao,
GenericCollectionResponseUsuarioCadastroEntity,
UsuarioCadastroEnumsSync,
UsuarioCadastroEnumVO,
GenericCollectionResponseUsuarioSistemaEntity,
AlterarSenhaRequest,
RegistrarSessaoRequest,
IncluirFichaCadastralRequest,
BaixarHoleriteRequest,
UploadHoleriteRequest,
BaixarInformeRequest,
TransferirSubordinadoRequest,
LoginRequest,
Token,
UsuarioEstabelecimentoRequest,
GenericCollectionResponseUsuarioEstabelecimentoDTO,
UsuarioEstabelecimentoDTO,
TimelineUsuariosRequest,
UsuarioTimelineDTO,
VendedorDTO,
VendedorEstabelecimentoRequest,
GenericCollectionResponseVendedorEstabelecimentoDTO,
VendedorEstabelecimentoDTO,
GenericCollectionResponseUsuarioAtivoDTO,
UsuarioAtivoDTO,
ValorMarcaEntity,
TipoValorMarcaEnum,
GenericCollectionResponseValorMarcaDTO,
ValorMarcaDTO,
GenericCollectionResponseDemonstrativoExpressDTO,
DemonstrativoExpressDTO,
RoteiroAgendamentoUnicoVO,
RoteiroAgendamentoQuinzenalVO,
RoteiroAgendamentoDiarioVO,
RoteiroAgendamentoSemanalVO,
RoteiroAgendamentoMensalVO,
TipoFrequenciaMensalEnum,
NullableDayOfWeek,
RoteiroDadosRupturaEntity,
TipoRupturaProdutoEnum,
RoteiroDadosReabastecimentoEntity,
EanAbastecidoVo,
RoteiroDadosCMEntity,
RoteiroDadosPontoExtraEntity,
TipoNegociacaoPontoExtraEnum,
TipoPontoExtraEnum,
RoteiroDadosMPDVEntity,
DadosMPDVVO,
TipoMPDVEnum,
RoteiroDadosPesquisaValidadeEntity,
RoteiroDadosCaixasAbertasEntity,
RoteiroDadosPesquisaEstoqueEntity,
RoteiroDadosPesquisaPrecoEntity,
PrecoConcorrenteVO,
RoteiroDadosGaleriaFotosEntity,
FotoGaleriaVO,
RoteiroDadosSugestaoPedidoEntity,
RoteiroDadosCamaraFriaEntity,
RoteiroDadosRegistroPresencaEntiy,
RoteiroDadosShareGondolaEntity,
RoteiroDadosPontoPromocionalEntity,
SituacaoInstalacaoPontoPromocional,
RoteiroDadosFormularioDinamicoEntity,
FormularioDinamicoRespotaVO,
RoteiroDadosRegistroFotosEntity,
RoteiroDadosInformarCNPJEntity,
RoteiroDadosPesquisaPrecoProdutoEntity,
EnumTipoComercializacao,
EnumTipoPromocao,
RoteiroDadosProdutoLojaEntity,
RoteiroDadosCheckListEPIEntity,
RoteiroDadosPesquisaDinamicaEntity,
RoteiroDadosCamaraFriaGeralEntity,
EnumTipoCamaraFria,
RoteiroDadosProspeccaoEntity,
RoteiroDadosDescarteEntity,
RoteiroDadosPesquisaConcorrenteEntity,
ArquivoExpressEntity,
RoteiroDadosPesquisaPrecoDTO,
RoteiroDadosFormularioDinamicoDTO,
RoteiroDadosPesquisaConcorrenteDTO,
RoteiroDadosMPDVDTO
} from './models';

/**
* Created with angular4-swagger-client-generator.
*/
@Injectable()
export class ApiClientService {

  private domain = 'http://localhost:5004';

  constructor(private http: HttpClient, private configuration: Configuration) {
    if (configuration) {
      this.domain = configuration.Server;
    }
  }

  /**
  * Method ApiAlbumfotosBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseAlbumFotosEntity>> {
    const uri = `/api/albumfotos/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAlbumFotosEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseAlbumFotosEntity>> {
    const uri = `/api/albumfotos/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAlbumFotosEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosConsultarporusuarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosConsultarporusuarioPost(request: ListarAlbumsPorUsuarioRequest): Observable<HttpResponse<GenericCollectionResponseAlbumFotosEntity>> {
    const uri = `/api/albumfotos/consultarporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAlbumFotosEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdGet(id: string): Observable<HttpResponse<AlbumFotosEntity>> {
    const uri = `/api/albumfotos/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<AlbumFotosEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosIncluirPost(request: AlbumFotosEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdAlterarPost(id: string, request: AlbumFotosEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosFotosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosFotosPost(request: ListarFotosRequest): Observable<HttpResponse<FotoVO[]>> {
    const uri = `/api/albumfotos/fotos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<FotoVO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosExcluirfotoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosExcluirfotoPost(request: FotosRemovidasEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/excluirfoto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdExportarpptGet
* @param id 
* @param templateId 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdExportarpptGet(id: string, templateId: string): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/${id}/exportarppt`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (templateId !== undefined && templateId !== null) {
      params = params.set('templateId', templateId + '');
    }
    return this.download('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosFilaincluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosFilaincluirPost(request: AlbumFotosFilaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/filaincluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdFilaalterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdFilaalterarPost(id: string, request: AlbumFotosFilaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/${id}/filaalterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosByIdFilaexcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosByIdFilaexcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/${id}/filaexcluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosDownloadPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosDownloadPost(request: AlbumFotoFilaRequest): Observable<HttpResponse<DownloadResponse>> {
    const uri = `/api/albumfotos/download`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<DownloadResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosLinkByIdByTemplateIdGet
* @param id 
* @param templateId 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosLinkByIdByTemplateIdGet(id: string, templateId: string): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/link/${id}/${templateId}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosListarPost(request: ListAlbumRequest): Observable<HttpResponse<GenericCollectionResponseAlbumFotosEntity>> {
    const uri = `/api/albumfotos/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAlbumFotosEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosSalvaravaliacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosSalvaravaliacaoPost(request: SalvarAvaliacaoFotoTOOQRequest): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/salvaravaliacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosRespostapromotorappfotoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRespostapromotorappfotoPost(request: RespostaPromotorAppFotoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/respostapromotorappfoto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosAvaliarfotowebPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosAvaliarfotowebPost(request: SalvarAvaliacaoFotoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/avaliarfotoweb`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosHistoricofotoPost
* @param nomeFoto 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosHistoricofotoPost(nomeFoto: string): Observable<HttpResponse<HistoricoAvaliacaoFotosDTO>> {
    const uri = `/api/albumfotos/historicofoto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<HistoricoAvaliacaoFotosDTO>('post', uri, headers, params, JSON.stringify(nomeFoto));
  }

  /**
  * Method ApiAlbumfotosListarmodelosGet
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosListarmodelosGet(): Observable<HttpResponse<AlbumFotosModeloDTO[]>> {
    const uri = `/api/albumfotos/listarmodelos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<AlbumFotosModeloDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosCadastrarmodeloPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosCadastrarmodeloPost(request: AlbumFotosModeloDTO): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/cadastrarmodelo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosUploadmodeloPost
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosUploadmodeloPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/albumfotos/uploadmodelo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosRecorrenteByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteByIdGet(id: string): Observable<HttpResponse<AlbumFotosRecorrenteEntity>> {
    const uri = `/api/albumfotos/recorrente/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<AlbumFotosRecorrenteEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosRecorrenteIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteIncluirPost(request: AlbumFotosRecorrenteEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/recorrente/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosRecorrenteExcluirByAlbumFotosRecorrenteIdDelete
* @param albumFotosRecorrenteId 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteExcluirByAlbumFotosRecorrenteIdDelete(albumFotosRecorrenteId: string): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/recorrente/excluir/${albumFotosRecorrenteId}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('delete', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosRecorrenteAlterarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteAlterarPost(request: AlbumFotosRecorrenteEntity): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/recorrente/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAlbumfotosRecorrenteGerarGet
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteGerarGet(): Observable<HttpResponse<any>> {
    const uri = `/api/albumfotos/recorrente/gerar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAlbumfotosRecorrenteListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAlbumfotosRecorrenteListarPost(request: ListAlbumRequest): Observable<HttpResponse<GenericCollectionResponseAlbumFotosRecorrenteEntity>> {
    const uri = `/api/albumfotos/recorrente/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAlbumFotosRecorrenteEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAtualizacaocadastralSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<AtualizacaoCadastralPlanejadoEntity[]>> {
    const uri = `/api/atualizacaocadastral/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<AtualizacaoCadastralPlanejadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAtualizacaocadastralSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralSyncPost(lista: AtualizacaoCadastralDadoColetadoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/atualizacaocadastral/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiAtualizacaocadastralCadastrarplanejadoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralCadastrarplanejadoPost(request: CadastrarAtualizacaoCadastralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/atualizacaocadastral/cadastrarplanejado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAtualizacaocadastralBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseListaAtualizacaoCadastralDTO>> {
    const uri = `/api/atualizacaocadastral/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseListaAtualizacaoCadastralDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAtualizacaocadastralDownloadexecutadoGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralDownloadexecutadoGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<AtualizacaoCadastralDadoColetadoEntity[]>> {
    const uri = `/api/atualizacaocadastral/downloadexecutado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<AtualizacaoCadastralDadoColetadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAtualizacaocadastralListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralListarPost(request: ListarAtualizacaoCadastralRequest): Observable<HttpResponse<GenericCollectionResponseRoteiroDadosPesquisaFormsDTO>> {
    const uri = `/api/atualizacaocadastral/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRoteiroDadosPesquisaFormsDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAtualizacaocadastralPodidByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralPodidByIdGet(id: string): Observable<HttpResponse<AtualizacaoCadastralDadoColetadoEntity>> {
    const uri = `/api/atualizacaocadastral/podid/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<AtualizacaoCadastralDadoColetadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiAtualizacaocadastralExportarcsvatualizacaocadastralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralExportarcsvatualizacaocadastralPost(request: ListarAtualizacaoCadastralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/atualizacaocadastral/exportarcsvatualizacaocadastral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAtualizacaocadastralExportarxlsxatualizacaocadastralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralExportarxlsxatualizacaocadastralPost(request: ListarAtualizacaoCadastralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/atualizacaocadastral/exportarxlsxatualizacaocadastral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAtualizacaocadastralFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiAtualizacaocadastralFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/atualizacaocadastral/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraGet
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraGet(): Observable<HttpResponse<BandeiraEstabelecimentoEntity[]>> {
    const uri = `/api/bandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseBandeiraEstabelecimentoEntity>> {
    const uri = `/api/bandeira/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseBandeiraEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseBandeiraEstabelecimentoEntity>> {
    const uri = `/api/bandeira/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseBandeiraEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraByIdGet(id: string): Observable<HttpResponse<BandeiraEstabelecimentoEntity>> {
    const uri = `/api/bandeira/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraRedeByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraRedeByIdGet(id: string): Observable<HttpResponse<BandeiraEstabelecimentoEntity[]>> {
    const uri = `/api/bandeira/rede/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraIncluirPost(entity: BandeiraEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiBandeiraByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraByIdAlterarPost(id: string, entity: BandeiraEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiBandeiraByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiBandeiraExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiBandeiraExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiBandeiraListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseBandeiraEstabelecimentoEntity>> {
    const uri = `/api/bandeira/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseBandeiraEstabelecimentoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiBandeiraPorestadoPost
* @param estados 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraPorestadoPost(estados: string[]): Observable<HttpResponse<BandeiraEstabelecimentoEntity[]>> {
    const uri = `/api/bandeira/porestado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoEntity[]>('post', uri, headers, params, JSON.stringify(estados));
  }

  /**
  * Method ApiBandeiraPorcidadePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraPorcidadePost(request: ListarPorUfsCidadesRequest): Observable<HttpResponse<BandeiraEstabelecimentoEntity[]>> {
    const uri = `/api/bandeira/porcidade`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoEntity[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiBandeiraImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/bandeira/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiBandeiraZonaByZonaNomesGet
* @param zona 
  * @return Full HTTP response as Observable
  */
  public ApiBandeiraZonaByZonaNomesGet(zona: string): Observable<HttpResponse<BandeiraEstabelecimentoBase[]>> {
    const uri = `/api/bandeira/zona/${zona}/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<BandeiraEstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdRedePost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedePost(id: number, entity: RedeEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/rede`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluirredePost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirredePost(id: number, entity: RedeEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirrede`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarredePost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarredePost(id: number, entity: RedeEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarrede`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdExcluirredePost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirredePost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirrede`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdRedeByIdBandeiraPost
* @param idRede 
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdBandeiraPost(idRede: number, id: number, entity: BandeiraERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${id}/bandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdRedeByIdIncluirbandeiraPost
* @param idRede 
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdIncluirbandeiraPost(idRede: number, id: number, request: BandeiraERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${id}/incluirbandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiErpByIdRedeByIdAlterarbandeiraPost
* @param idRede 
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdAlterarbandeiraPost(idRede: number, id: number, request: BandeiraERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${id}/alterarbandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiErpByIdExcluirbandeiraPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirbandeiraPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirbandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdRedeByIdBandeiraByIdIncluirestabelecimentoPost
* @param idRede 
* @param idBandeira 
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdBandeiraByIdIncluirestabelecimentoPost(idRede: number, idBandeira: number, id: number, request: LojaERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${idBandeira}/${id}/incluirestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiErpByIdRedeByIdBandeiraByIdAlterarestabelecimentoPost
* @param idRede 
* @param idBandeira 
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdBandeiraByIdAlterarestabelecimentoPost(idRede: number, idBandeira: number, id: number, request: LojaERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${idBandeira}/${id}/alterarestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiErpByIdRedeByIdBandeiraByIdEstabelecimentoPost
* @param idRede 
* @param idBandeira 
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdRedeByIdBandeiraByIdEstabelecimentoPost(idRede: number, idBandeira: number, id: number, entity: LojaERP): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${idRede}/${idBandeira}/${id}/estabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdExcluirestabelecimentoPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirestabelecimentoPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdIncluirmarcaPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirmarcaPost(id: number, entity: MarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirmarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdMarcaPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdMarcaPost(id: number, entity: MarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/marca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarmarcaPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarmarcaPost(id: number, entity: MarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarmarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdSegmentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdSegmentoPost(id: number, entity: SegmentoProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/segmento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluirsegmentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirsegmentoPost(id: number, entity: SegmentoProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirsegmento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdExcluirmarcaPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirmarcaPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirmarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdExcluirsegmentoPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirsegmentoPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirsegmento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdAlterarsegmentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarsegmentoPost(id: number, entity: SegmentoProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarsegmento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluirusuarioPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirusuarioPost(id: number, entity: UsuarioSistemaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarusuarioPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarusuarioPost(id: number, entity: UsuarioSistemaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdExcluirusuarioPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirusuarioPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdExcluirorcamentoPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdExcluirorcamentoPost(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/excluirorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdAtualizarMarcaEstabelecimentoERPGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAtualizarMarcaEstabelecimentoERPGet(id: number): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/atualizarMarcaEstabelecimentoERP`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiErpAtualizarsegmentodafamiliaGet
  * @return Full HTTP response as Observable
  */
  public ApiErpAtualizarsegmentodafamiliaGet(): Observable<HttpResponse<any>> {
    const uri = `/api/erp/atualizarsegmentodafamilia`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiErpByIdCanalestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdCanalestabelecimentoPost(id: number, entity: CanalEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/canalestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluircanalestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluircanalestabelecimentoPost(id: number, entity: CanalEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluircanalestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarcanalestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarcanalestabelecimentoPost(id: number, entity: CanalEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarcanalestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdStatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdStatusestabelecimentoPost(id: number, entity: StatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/statusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluirstatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirstatusestabelecimentoPost(id: number, entity: StatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirstatusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarstatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarstatusestabelecimentoPost(id: number, entity: StatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarstatusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdSubstatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdSubstatusestabelecimentoPost(id: number, entity: SubstatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/substatusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdIncluirsubstatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdIncluirsubstatusestabelecimentoPost(id: number, entity: SubstatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/incluirsubstatusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiErpByIdAlterarsubstatusestabelecimentoPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiErpByIdAlterarsubstatusestabelecimentoPost(id: number, entity: SubstatusEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/erp/${id}/alterarsubstatusestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiCartasCriarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCartasCriarPost(request: CartasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/cartas/criar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCartasModelosGet
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosGet(): Observable<HttpResponse<ModeloCartaEntity[]>> {
    const uri = `/api/cartas/modelos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ModeloCartaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCartasModelosBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseModeloCartaEntity>> {
    const uri = `/api/cartas/modelos/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseModeloCartaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCartasListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCartasListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseModeloCartaEntity>> {
    const uri = `/api/cartas/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseModeloCartaEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCartasUploadmodeloPost
  * @return Full HTTP response as Observable
  */
  public ApiCartasUploadmodeloPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/cartas/uploadmodelo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiCartasModelosByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosByIdGet(id: string): Observable<HttpResponse<ModeloCartaEntity>> {
    const uri = `/api/cartas/modelos/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ModeloCartaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCartasModelosIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosIncluirPost(entity: ModeloCartaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/cartas/modelos/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiCartasModelosByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosByIdAlterarPost(id: string, entity: ModeloCartaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/cartas/modelos/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiCartasModelosByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCartasModelosByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/cartas/modelos/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiCartasCriartermolgpdPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCartasCriartermolgpdPost(request: CartasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/cartas/criartermolgpd`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoGet
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoGet(): Observable<HttpResponse<ProdutoFamiliaBase[]>> {
    const uri = `/api/catalogoprodutos/familiaproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoFamiliaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoconcorrenteGet
* @param concorrente 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoconcorrenteGet(concorrente: boolean): Observable<HttpResponse<ProdutoFamiliaBase[]>> {
    const uri = `/api/catalogoprodutos/familiaprodutoconcorrente`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (concorrente !== undefined && concorrente !== null) {
      params = params.set('concorrente', concorrente + '');
    }
    return this.sendRequest<ProdutoFamiliaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoByIdProdutoGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoByIdProdutoGet(id: string): Observable<HttpResponse<ProdutoBase[]>> {
    const uri = `/api/catalogoprodutos/familiaproduto/${id}/produto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoByIdPesquisaformsGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoByIdPesquisaformsGet(id: string): Observable<HttpResponse<ProdutoBaseComFoto[]>> {
    const uri = `/api/catalogoprodutos/familiaproduto/${id}/pesquisaforms`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoBaseComFoto[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ProdutoFamiliaEntity[]>> {
    const uri = `/api/catalogoprodutos/familiaproduto/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ProdutoFamiliaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosProdutoSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosProdutoSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ProdutoEntity[]>> {
    const uri = `/api/catalogoprodutos/produto/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ProdutoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosFamiliaprodutoFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosFamiliaprodutoFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/catalogoprodutos/familiaproduto/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCatalogoprodutosProdutoFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiCatalogoprodutosProdutoFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/catalogoprodutos/produto/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCategoriaprodutoGet
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoGet(): Observable<HttpResponse<CategoriaProdutoEntity[]>> {
    const uri = `/api/categoriaproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<CategoriaProdutoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCategoriaprodutoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCategoriaprodutoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCategoriaprodutoListarcategorioprodutoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoListarcategorioprodutoPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseCategoriaProdutoEntity>> {
    const uri = `/api/categoriaproduto/listarcategorioproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseCategoriaProdutoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCategoriaprodutoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoByIdGet(id: string): Observable<HttpResponse<CategoriaProdutoEntity>> {
    const uri = `/api/categoriaproduto/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<CategoriaProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCategoriaprodutoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoIncluirPost(entity: CategoriaProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiCategoriaprodutoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoByIdAlterarPost(id: string, entity: CategoriaProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiCategoriaprodutoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiCategoriaprodutoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/categoriaproduto/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiCategoriaprodutoListarByListarExcluidosGet
* @param listarExcluidos 
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoListarByListarExcluidosGet(listarExcluidos: boolean): Observable<HttpResponse<CategoriaProdutoDTO[]>> {
    const uri = `/api/categoriaproduto/listar/${listarExcluidos}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<CategoriaProdutoDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiCategoriaprodutoUploadplanogramaPost
  * @return Full HTTP response as Observable
  */
  public ApiCategoriaprodutoUploadplanogramaPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/categoriaproduto/uploadplanograma`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiChatmobileIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiChatmobileIncluirPost(request: ChatMensagemEntity): Observable<HttpResponse<any>> {
    const uri = `/api/chatmobile/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiChatmobileDownloadGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiChatmobileDownloadGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/chatmobile/download`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiChatmobileUploadPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiChatmobileUploadPost(lista: ChatMensagemEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/chatmobile/upload`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiChatmobileUsuarioschatGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiChatmobileUsuarioschatGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/chatmobile/usuarioschat`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoMobileSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoMobileSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ConfiguracaoMobileEntity[]>> {
    const uri = `/api/configuracao/mobile/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ConfiguracaoMobileEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoGet
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoGet(): Observable<HttpResponse<ConfiguracaoMobileEntity>> {
    const uri = `/api/configuracao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfiguracaoMobileEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoByIdAlterarPut
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoByIdAlterarPut(id: string, entity: ConfiguracaoMobileEntity): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('put', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiConfiguracaoSiteconfigGet
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoSiteconfigGet(): Observable<HttpResponse<ConfiguracaoWebDTO>> {
    const uri = `/api/configuracao/siteconfig`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfiguracaoWebDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoSalvarPost
* @param config 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoSalvarPost(config: ConfiguracaoWebDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/salvar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(config));
  }

  /**
  * Method ApiConfiguracaoInserirconfigGet
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoInserirconfigGet(): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/inserirconfig`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUploadmarcadaguaPost
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUploadmarcadaguaPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/configuracao/uploadmarcadagua`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioSalvarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioSalvarPost(request: ConfiguracaoWebDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/usuario/salvar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiConfiguracaoUsuarioByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioByIdGet(id: string): Observable<HttpResponse<ConfiguracaoWebDTO>> {
    const uri = `/api/configuracao/usuario/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfiguracaoWebDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoAssinanteSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoAssinanteSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<TipoTarefaRoteiroEnum[]>> {
    const uri = `/api/configuracao/assinante/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<TipoTarefaRoteiroEnum[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ConfiguracaoWebDTO>> {
    const uri = `/api/configuracao/usuarioconfig/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ConfiguracaoWebDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ConfiguracaoWebDTO>> {
    const uri = `/api/configuracao/usuarioconfig/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ConfiguracaoWebDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoMobileconfigFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoMobileconfigFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/mobileconfig/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoMobileconfigFlutterAtualizarversaoByVersaoIOSByVersaoAndroidPut
* @param versaoIOS 
* @param versaoAndroid 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoMobileconfigFlutterAtualizarversaoByVersaoIOSByVersaoAndroidPut(versaoIOS: number, versaoAndroid: number): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/mobileconfig/flutter/atualizarversao/${versaoIOS}/${versaoAndroid}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('put', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigappByUsuarioIdGet
* @param usuarioId 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigappByUsuarioIdGet(usuarioId: string): Observable<HttpResponse<ConfiguracaoUsuarioPerfilAppDTO>> {
    const uri = `/api/configuracao/usuarioconfigapp/${usuarioId}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfiguracaoUsuarioPerfilAppDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigappRemoverByConfigIdGet
* @param configId 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigappRemoverByConfigIdGet(configId: string): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/usuarioconfigapp/remover/${configId}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigappIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigappIncluirPost(request: ConfiguracaoUsuarioPerfilAppDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/usuarioconfigapp/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiConfiguracaoUsuarioconfigappAlterarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoUsuarioconfigappAlterarPost(request: ConfiguracaoUsuarioPerfilAppDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/usuarioconfigapp/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiConfiguracaoPerfilconfigappByPerfilGet
* @param perfil 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoPerfilconfigappByPerfilGet(perfil: string): Observable<HttpResponse<ConfiguracaoUsuarioPerfilAppDTO>> {
    const uri = `/api/configuracao/perfilconfigapp/${perfil}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfiguracaoUsuarioPerfilAppDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoPerfilconfigappRemoverByPerfilGet
* @param perfil 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoPerfilconfigappRemoverByPerfilGet(perfil: string): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/perfilconfigapp/remover/${perfil}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfiguracaoPerfilconfigappIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoPerfilconfigappIncluirPost(request: ConfiguracaoUsuarioPerfilAppDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/perfilconfigapp/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiConfiguracaoPerfilconfigappAlterarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfiguracaoPerfilconfigappAlterarPost(request: ConfiguracaoUsuarioPerfilAppDTO): Observable<HttpResponse<any>> {
    const uri = `/api/configuracao/perfilconfigapp/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiConfigDadoscoletadosGet
  * @return Full HTTP response as Observable
  */
  public ApiConfigDadoscoletadosGet(): Observable<HttpResponse<ConfigDadosColetadosEntity>> {
    const uri = `/api/config/dadoscoletados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ConfigDadosColetadosEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiConfigCadastrarconfigdadoscoletadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiConfigCadastrarconfigdadoscoletadosPost(request: ConfigDadosColetadosEntity): Observable<HttpResponse<any>> {
    const uri = `/api/config/cadastrarconfigdadoscoletados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEmpresaGet
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaGet(): Observable<HttpResponse<EmpresaEntity[]>> {
    const uri = `/api/empresa`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EmpresaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEmpresaBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseEmpresaEntity>> {
    const uri = `/api/empresa/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseEmpresaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEmpresaListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseEmpresaEntity>> {
    const uri = `/api/empresa/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseEmpresaEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEmpresaByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaByIdGet(id: string): Observable<HttpResponse<EmpresaEntity>> {
    const uri = `/api/empresa/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EmpresaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEmpresaIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaIncluirPost(entity: EmpresaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/empresa/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiEmpresaByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaByIdAlterarPost(id: string, entity: EmpresaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/empresa/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiEmpresaByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEmpresaByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/empresa/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiEnderecoUnidadeFederativaGet
  * @return Full HTTP response as Observable
  */
  public ApiEnderecoUnidadeFederativaGet(): Observable<HttpResponse<UnidadeFederativaEntity[]>> {
    const uri = `/api/endereco/UnidadeFederativa`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UnidadeFederativaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEnderecoObterPorCepGet
  * @return Full HTTP response as Observable
  */
  public ApiEnderecoObterPorCepGet(cep: string): Observable<HttpResponse<any>> {
    const uri = `/api/endereco/obter-por-cep/${cep}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEnderecoOterGeolocalizacaoGet
  * @return Full HTTP response as Observable
  */
  public ApiEnderecoOterGeolocalizacaoGet(endereco: string): Observable<HttpResponse<any>> {
    const uri = `/api/endereco/obter-geolocalizacao/${endereco}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoGet(): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoListarcomiderpGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoListarcomiderpGet(): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/listarcomiderp`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseEstabelecimentoEntity>> {
    const uri = `/api/estabelecimento/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseEstabelecimentoEntity>> {
    const uri = `/api/estabelecimento/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoByIdGet(id: string): Observable<HttpResponse<EstabelecimentoEntity>> {
    const uri = `/api/estabelecimento/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoUltimoGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoUltimoGet(): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/ultimo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoNomesGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoNomesGet(): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoRedeByIdNomesGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoRedeByIdNomesGet(id: string): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/rede/${id}/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoZonaByZonaNomesGet
* @param zona 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoZonaByZonaNomesGet(zona: string): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/zona/${zona}/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoByZonaByBandeiraNomesGet
* @param zona 
* @param bandeira 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoByZonaByBandeiraNomesGet(zona: string, bandeira: string): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/${zona}/${bandeira}/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<EstabelecimentoEntity[]>> {
    const uri = `/api/estabelecimento/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<EstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoIncluirPost(entity: EstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiEstabelecimentoByIdAlterarPut
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoByIdAlterarPut(id: string, entity: EstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('put', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiEstabelecimentoByIdExcluirDelete
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoByIdExcluirDelete(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('delete', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoEstabelecimentoporufPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoEstabelecimentoporufPost(request: ListarLojasPorUfRequest): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/estabelecimentoporuf`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoCnpjexisteByCnpjByIdGet
* @param cnpj 
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoCnpjexisteByCnpjByIdGet(cnpj: string, id: string): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/cnpjexiste/${cnpj}/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoCodigoexisteByCodigoGet
* @param codigo 
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoCodigoexisteByCodigoGet(codigo: number, id: string): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/codigoexiste/${codigo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseEstabelecimentoEntity>> {
    const uri = `/api/estabelecimento/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseEstabelecimentoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoObtercidadesGet
* @param uf 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoObtercidadesGet(uf: string): Observable<HttpResponse<string[]>> {
    const uri = `/api/estabelecimento/obtercidades`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (uf !== undefined && uf !== null) {
      params = params.set('uf', uf + '');
    }
    return this.sendRequest<string[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoObterbairrosGet
* @param cidade 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoObterbairrosGet(cidade: string): Observable<HttpResponse<string[]>> {
    const uri = `/api/estabelecimento/obterbairros`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (cidade !== undefined && cidade !== null) {
      params = params.set('cidade', cidade + '');
    }
    return this.sendRequest<string[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoObterzonasGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoObterzonasGet(): Observable<HttpResponse<string[]>> {
    const uri = `/api/estabelecimento/obterzonas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<string[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoPorbandeiraenderecoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoPorbandeiraenderecoPost(request: ListarEstabelecimentosPorBandeiraEnderecoRequest): Observable<HttpResponse<EstabelecimentoBase[]>> {
    const uri = `/api/estabelecimento/porbandeiraendereco`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EstabelecimentoBase[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoListarcidadesPost
* @param estados 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoListarcidadesPost(estados: string[]): Observable<HttpResponse<string[]>> {
    const uri = `/api/estabelecimento/listarcidades`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<string[]>('post', uri, headers, params, JSON.stringify(estados));
  }

  /**
  * Method ApiEstabelecimentoConsultarcepByCepGet
* @param cep 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoConsultarcepByCepGet(cep: string): Observable<HttpResponse<EnderecoVO>> {
    const uri = `/api/estabelecimento/consultarcep/${cep}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EnderecoVO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoConsultarenderecoByEnderecoGet
* @param endereco 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoConsultarenderecoByEnderecoGet(endereco: string): Observable<HttpResponse<GeocodeResponse>> {
    const uri = `/api/estabelecimento/consultarendereco/${endereco}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GeocodeResponse>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/estabelecimento/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiEstabelecimentoCanalGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoCanalGet(): Observable<HttpResponse<CanalEstabelecimentoEntity[]>> {
    const uri = `/api/estabelecimento/canal`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<CanalEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoStatusGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoStatusGet(): Observable<HttpResponse<StatusEstabelecimentoEntity[]>> {
    const uri = `/api/estabelecimento/status`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<StatusEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiEstabelecimentoSubstatusGet
  * @return Full HTTP response as Observable
  */
  public ApiEstabelecimentoSubstatusGet(): Observable<HttpResponse<SubstatusEstabelecimentoEntity[]>> {
    const uri = `/api/estabelecimento/substatus`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubstatusEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosGet
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosGet(): Observable<HttpResponse<ProdutoFamiliaBase[]>> {
    const uri = `/api/familiaprodutos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoFamiliaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosUploadlayoutPost
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosUploadlayoutPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/familiaprodutos/uploadlayout`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseProdutoFamiliaEntity>> {
    const uri = `/api/familiaprodutos/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoFamiliaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseProdutoFamiliaEntity>> {
    const uri = `/api/familiaprodutos/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoFamiliaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosByIdGet(id: string): Observable<HttpResponse<ProdutoFamiliaEntity>> {
    const uri = `/api/familiaprodutos/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoFamiliaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosIncluirPost(entity: ProdutoFamiliaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiFamiliaprodutosByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosByIdAlterarPost(id: string, entity: ProdutoFamiliaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiFamiliaprodutosByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiFamiliaprodutosProdutofamiliaporidPost
* @param ids 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosProdutofamiliaporidPost(ids: string[]): Observable<HttpResponse<ProdutoFamiliaBase[]>> {
    const uri = `/api/familiaprodutos/produtofamiliaporid`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoFamiliaBase[]>('post', uri, headers, params, JSON.stringify(ids));
  }

  /**
  * Method ApiFamiliaprodutosExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFamiliaprodutosExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFamiliaprodutosListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseProdutoFamiliaEntity>> {
    const uri = `/api/familiaprodutos/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoFamiliaEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFamiliaprodutosImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFamiliaprodutosImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/familiaprodutos/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFeriadoGet
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoGet(): Observable<HttpResponse<FeriadoEntity[]>> {
    const uri = `/api/feriado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<FeriadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFeriadoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseFeriadoEntity>> {
    const uri = `/api/feriado/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFeriadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFeriadoConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseFeriadoEntity>> {
    const uri = `/api/feriado/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFeriadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFeriadoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoByIdGet(id: string): Observable<HttpResponse<FeriadoEntity>> {
    const uri = `/api/feriado/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<FeriadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFeriadoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoIncluirPost(entity: FeriadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiFeriadoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoByIdAlterarPost(id: string, entity: FeriadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiFeriadoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiFeriadoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFeriadoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFeriadoListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseFeriadoDTO>> {
    const uri = `/api/feriado/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFeriadoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFeriadoSubirferiadosnacionaisPost
* @param ano 
  * @return Full HTTP response as Observable
  */
  public ApiFeriadoSubirferiadosnacionaisPost(ano: number): Observable<HttpResponse<any>> {
    const uri = `/api/feriado/subirferiadosnacionais`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (ano !== undefined && ano !== null) {
      params = params.set('ano', ano + '');
    }
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoListarTodosGet
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoListarTodosGet(): Observable<HttpResponse<GenericCollectionResponseFormularioDinamicoEntity>> {
    const uri = `/api/FormularioDinamico/ListarTodos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFormularioDinamicoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoListarbaseGet
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoListarbaseGet(): Observable<HttpResponse<FormularioDinamicoBase[]>> {
    const uri = `/api/FormularioDinamico/listarbase`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<FormularioDinamicoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseFormularioDinamicoEntity>> {
    const uri = `/api/FormularioDinamico/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFormularioDinamicoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoByIdGet(id: string): Observable<HttpResponse<FormularioDinamicoEntity>> {
    const uri = `/api/FormularioDinamico/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<FormularioDinamicoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoIncluirPost(request: FormularioDinamicoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/FormularioDinamico/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFormularioDinamicoByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoByIdAlterarPost(id: string, request: FormularioDinamicoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/FormularioDinamico/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiFormularioDinamicoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/FormularioDinamico/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiFormularioDinamicoSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiFormularioDinamicoSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<FormularioDinamicoEntity[]>> {
    const uri = `/api/FormularioDinamico/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<FormularioDinamicoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiHorapormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiHorapormarcaPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseHoraPorMarcaDTO>> {
    const uri = `/api/horapormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseHoraPorMarcaDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiHorapormarcaExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiHorapormarcaExportarcsvPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/horapormarca/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiHorapormarcaExportarhoramarcaroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiHorapormarcaExportarhoramarcaroboPost(request: GenericRelatorioRequest): Observable<HttpResponse<HoraMarcaDTO[]>> {
    const uri = `/api/horapormarca/exportarhoramarcarobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<HoraMarcaDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiHorapormarcaExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiHorapormarcaExportarxlsxPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/horapormarca/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiAbc123initializerGet
  * @return Full HTTP response as Observable
  */
  public ApiAbc123initializerGet(): Observable<HttpResponse<any>> {
    const uri = `/api/abc123initializer`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiLogDispositivoByIdUsuarioSistemaByDataGet
* @param idUsuarioSistema 
* @param data 
  * @return Full HTTP response as Observable
  */
  public ApiLogDispositivoByIdUsuarioSistemaByDataGet(idUsuarioSistema: string, data: string): Observable<HttpResponse<LogDispositivoVO[]>> {
    const uri = `/api/log/dispositivo/${idUsuarioSistema}/${data}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<LogDispositivoVO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiLogDispositivoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiLogDispositivoPost(request: ListarLogsRequest): Observable<HttpResponse<GenericCollectionResponseLogDispositivoVO>> {
    const uri = `/api/log/dispositivo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseLogDispositivoVO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiLogGeolocalizacaoByIdUsuarioSistemaByDataGet
* @param idUsuarioSistema 
* @param data 
  * @return Full HTTP response as Observable
  */
  public ApiLogGeolocalizacaoByIdUsuarioSistemaByDataGet(idUsuarioSistema: string, data: string): Observable<HttpResponse<LogGeolocalizacaoVO[]>> {
    const uri = `/api/log/geolocalizacao/${idUsuarioSistema}/${data}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<LogGeolocalizacaoVO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiLogDispositivoSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiLogDispositivoSyncPost(lista: LogDispositivoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/log/dispositivo/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiLogGeolocalizacaoSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiLogGeolocalizacaoSyncPost(lista: LogGeolocalizacaoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/log/geolocalizacao/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiMarcaGet
  * @return Full HTTP response as Observable
  */
  public ApiMarcaGet(): Observable<HttpResponse<MarcaBase[]>> {
    const uri = `/api/marca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<MarcaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseMarcaEntity>> {
    const uri = `/api/marca/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseMarcaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseMarcaEntity>> {
    const uri = `/api/marca/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseMarcaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaByIdGet(id: string): Observable<HttpResponse<MarcaEntity>> {
    const uri = `/api/marca/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<MarcaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaIncluirPost(entity: MarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/marca/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiMarcaByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaByIdAlterarPost(id: string, entity: MarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/marca/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiMarcaByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/marca/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/marca/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiMarcaExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/marca/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiMarcaListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseMarcaEntity>> {
    const uri = `/api/marca/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseMarcaEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiMarcaListaridchatGet
  * @return Full HTTP response as Observable
  */
  public ApiMarcaListaridchatGet(): Observable<HttpResponse<MarcaIdChatDTO[]>> {
    const uri = `/api/marca/listaridchat`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<MarcaIdChatDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMarcaImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMarcaImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/marca/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiMonitoramentoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiMonitoramentoByIdGet(id: string): Observable<HttpResponse<ResumoRelatorioPromotor[]>> {
    const uri = `/api/monitoramento/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoRelatorioPromotor[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiMonitoramentoDispositivosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMonitoramentoDispositivosPost(request: LiberarDispositivoRequest): Observable<HttpResponse<ResumoUsuarioLiberarDispositivoDTO[]>> {
    const uri = `/api/monitoramento/dispositivos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoUsuarioLiberarDispositivoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiMonitoramentoSuperiorswitchdispositivoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiMonitoramentoSuperiorswitchdispositivoPost(request: SuperiorHabilitarDispositivoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/monitoramento/superiorswitchdispositivo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiNotificacaoIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoIncluirPost(request: NotificacaoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/notificacao/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiNotificacaoByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoByIdAlterarPost(id: string, request: NotificacaoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/notificacao/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiNotificacaoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/notificacao/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaoListarparausuarioByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoListarparausuarioByIdGet(id: string): Observable<HttpResponse<NotificacaoEntity[]>> {
    const uri = `/api/notificacao/listarparausuario/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<NotificacaoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaoListardtoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoListardtoByIdGet(id: string): Observable<HttpResponse<NotificacaoResponse>> {
    const uri = `/api/notificacao/listardto/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<NotificacaoResponse>('get', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaoVisualizadoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaoVisualizadoByIdGet(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/notificacao/visualizado/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaomobileIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileIncluirPost(request: NotificacaoMobileDTO): Observable<HttpResponse<any>> {
    const uri = `/api/notificacaomobile/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiNotificacaomobileCancelPost
* @param _id 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileCancelPost(_id: string): Observable<HttpResponse<any>> {
    const uri = `/api/notificacaomobile/cancel`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (_id !== undefined && _id !== null) {
      params = params.set('_id', _id + '');
    }
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaomobileListaridnotificacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileListaridnotificacaoPost(request: NotificacaoCancelarListarRequest): Observable<HttpResponse<GenericCollectionResponseNotificacaoMobileCancelarDTO>> {
    const uri = `/api/notificacaomobile/listaridnotificacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseNotificacaoMobileCancelarDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiNotificacaomobileTesteGet
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileTesteGet(): Observable<HttpResponse<any>> {
    const uri = `/api/notificacaomobile/teste`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaomobileUploadimagemnotificacaoPost
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileUploadimagemnotificacaoPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/notificacaomobile/uploadimagemnotificacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaomobileListartodostoGet
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileListartodostoGet(): Observable<HttpResponse<string[]>> {
    const uri = `/api/notificacaomobile/listartodosto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<string[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiNotificacaomobileListartodosGet
  * @return Full HTTP response as Observable
  */
  public ApiNotificacaomobileListartodosGet(): Observable<HttpResponse<NotificacaoMobileEntity[]>> {
    const uri = `/api/notificacaomobile/listartodos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<NotificacaoMobileEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPagexpressCadastrarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiPagexpressCadastrarPost(request: PagamentoExpressEntity): Observable<HttpResponse<any>> {
    const uri = `/api/pagexpress/cadastrar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiPagexpressListarByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiPagexpressListarByIdGet(id: string): Observable<HttpResponse<PagamentoExpressEntity[]>> {
    const uri = `/api/pagexpress/listar/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<PagamentoExpressEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPagexpressListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiPagexpressListarPost(request: ListarPagExpressRequest): Observable<HttpResponse<GenericCollectionResponsePagamentoExpressEntity>> {
    const uri = `/api/pagexpress/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponsePagamentoExpressEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiPagexpressExcluirByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiPagexpressExcluirByIdGet(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/pagexpress/excluir/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsIncluirPost(entity: PesquisaFormsEntity): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiPesquisaformsByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsByIdAlterarPost(id: string, request: PesquisaFormsEntity): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiPesquisaformsByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<PesquisaFormsEntity[]>> {
    const uri = `/api/pesquisaforms/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<PesquisaFormsEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponsePesquisaFormsEntity>> {
    const uri = `/api/pesquisaforms/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponsePesquisaFormsEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsByIdGet(id: string): Observable<HttpResponse<PesquisaFormsEntity>> {
    const uri = `/api/pesquisaforms/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<PesquisaFormsEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsListarGet
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsListarGet(): Observable<HttpResponse<PesquisaFormsEntity[]>> {
    const uri = `/api/pesquisaforms/Listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<PesquisaFormsEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsConsultarcodigoultimapesquisaGet
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsConsultarcodigoultimapesquisaGet(): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/consultarcodigoultimapesquisa`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsAddhashGet
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsAddhashGet(): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/addhash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsAddhashrespostaGet
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsAddhashrespostaGet(): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/addhashresposta`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsListarnomesGet
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsListarnomesGet(): Observable<HttpResponse<PesquisaFormsNomeDTO[]>> {
    const uri = `/api/pesquisaforms/listarnomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<PesquisaFormsNomeDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsUploadthumbnailPost
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsUploadthumbnailPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/pesquisaforms/uploadthumbnail`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiPesquisaformsLitarTarefaPersonalizadaPost
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiPesquisaformsLitarTarefaPersonalizadaPost(termo: string): Observable<HttpResponse<GenericCollectionResponsePesquisaFormsEntity>> {
    const uri = `/api/pesquisaforms/litarTarefaPersonalizada`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (termo !== undefined && termo !== null) {
      params = params.set('termo', termo + '');
    }
    return this.sendRequest<GenericCollectionResponsePesquisaFormsEntity>('post', uri, headers, params, null);
  }

  /**
  * Method ApiPingGet
  * @return Full HTTP response as Observable
  */
  public ApiPingGet(): Observable<HttpResponse<any>> {
    const uri = `/api/ping`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoGet
  * @return Full HTTP response as Observable
  */
  public ApiProdutoGet(): Observable<HttpResponse<ProdutoBase[]>> {
    const uri = `/api/produto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseProdutoEntity>> {
    const uri = `/api/produto/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseProdutoEntity>> {
    const uri = `/api/produto/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoByIdGet(id: string): Observable<HttpResponse<ProdutoEntity>> {
    const uri = `/api/produto/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoIncluirPost(entity: ProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/produto/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProdutoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoByIdAlterarPost(id: string, entity: ProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/produto/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProdutoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/produto/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/produto/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/produto/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseProdutoEntity>> {
    const uri = `/api/produto/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseProdutoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoUploadfotoPost
  * @return Full HTTP response as Observable
  */
  public ApiProdutoUploadfotoPost(): Observable<HttpResponse<UploadFotoProdutoDTO>> {
    const uri = `/api/produto/uploadfoto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFotoProdutoDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/produto/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoPorfamiliasubcategoriaByIdFamiliaByIdSubCategoriaByListarExcluidosGet
* @param idFamilia 
* @param idSubCategoria 
* @param listarExcluidos 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoPorfamiliasubcategoriaByIdFamiliaByIdSubCategoriaByListarExcluidosGet(idFamilia: string, idSubCategoria: string, listarExcluidos: boolean): Observable<HttpResponse<ProdutoBase[]>> {
    const uri = `/api/produto/porfamiliasubcategoria/${idFamilia}/${idSubCategoria}/${listarExcluidos}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoIncluirPost(entity: ProdutoEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProdutoestabelecimentoByIdAlterarPut
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoByIdAlterarPut(id: string, entity: ProdutoEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('put', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProdutoestabelecimentoExportarvinculosporFamiliaxlsxPost
* @param idBandeira 
* @param idEstabelecimento 
* @param idProdutoFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoExportarvinculosporFamiliaxlsxPost(idBandeira: string, idEstabelecimento: string, idProdutoFamilia: string): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/exportarvinculosporFamiliaxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idBandeira !== undefined && idBandeira !== null) {
      params = params.set('idBandeira', idBandeira + '');
    }
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    if (idProdutoFamilia !== undefined && idProdutoFamilia !== null) {
      params = params.set('idProdutoFamilia', idProdutoFamilia + '');
    }
    return this.download('post', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoListarvinculosporFamiliaPost
* @param idBandeira 
* @param idEstabelecimento 
* @param idProdutoFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoListarvinculosporFamiliaPost(idBandeira: string, idEstabelecimento: string, idProdutoFamilia: string): Observable<HttpResponse<VinculoFamiliaDTO[]>> {
    const uri = `/api/produtoestabelecimento/listarvinculosporFamilia`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idBandeira !== undefined && idBandeira !== null) {
      params = params.set('idBandeira', idBandeira + '');
    }
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    if (idProdutoFamilia !== undefined && idProdutoFamilia !== null) {
      params = params.set('idProdutoFamilia', idProdutoFamilia + '');
    }
    return this.sendRequest<VinculoFamiliaDTO[]>('post', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoExportarvinculosxlsxPost
* @param idBandeira 
* @param idEstabelecimento 
* @param idProdutoFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoExportarvinculosxlsxPost(idBandeira: string, idEstabelecimento: string, idProdutoFamilia: string): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/exportarvinculosxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idBandeira !== undefined && idBandeira !== null) {
      params = params.set('idBandeira', idBandeira + '');
    }
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    if (idProdutoFamilia !== undefined && idProdutoFamilia !== null) {
      params = params.set('idProdutoFamilia', idProdutoFamilia + '');
    }
    return this.download('post', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoProdutoslojasPost
* @param IdsFamilias 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoProdutoslojasPost(IdsFamilias: string[]): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/produtoslojas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(IdsFamilias));
  }

  /**
  * Method ApiProdutoestabelecimentoPesquisarmarcasvinculadasGet
* @param idEstabelecimento 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoPesquisarmarcasvinculadasGet(idEstabelecimento: string): Observable<HttpResponse<MarcaHora[]>> {
    const uri = `/api/produtoestabelecimento/pesquisarmarcasvinculadas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    return this.sendRequest<MarcaHora[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoPesquisarvinculosGet
* @param idEstabelecimento 
* @param idProdutoFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoPesquisarvinculosGet(idEstabelecimento: string, idProdutoFamilia: string): Observable<HttpResponse<Vinculado[]>> {
    const uri = `/api/produtoestabelecimento/pesquisarvinculos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    if (idProdutoFamilia !== undefined && idProdutoFamilia !== null) {
      params = params.set('idProdutoFamilia', idProdutoFamilia + '');
    }
    return this.sendRequest<Vinculado[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoBuscarGet
* @param idEstabelecimento 
* @param idProdutoFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoBuscarGet(idEstabelecimento: string, idProdutoFamilia: string): Observable<HttpResponse<ProdutoEstabelecimentoDTO>> {
    const uri = `/api/produtoestabelecimento/buscar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idEstabelecimento !== undefined && idEstabelecimento !== null) {
      params = params.set('idEstabelecimento', idEstabelecimento + '');
    }
    if (idProdutoFamilia !== undefined && idProdutoFamilia !== null) {
      params = params.set('idProdutoFamilia', idProdutoFamilia + '');
    }
    return this.sendRequest<ProdutoEstabelecimentoDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoBuscarporbandeiraPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoBuscarporbandeiraPost(request: ProdutosPorBandeiraRequest): Observable<HttpResponse<ProdutoEstabelecimentoDTO>> {
    const uri = `/api/produtoestabelecimento/buscarporbandeira`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoEstabelecimentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoVincularPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoVincularPost(request: ProdutoEstabelecimentoDTO): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/vincular`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoVincularmultiploPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoVincularmultiploPost(request: ProdutoEstabelecimentosDTO): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/vincularmultiplo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoVincularmultiploporzonaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoVincularmultiploporzonaPost(request: ProdutoEstabelecimentosDTO): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/vincularmultiploporzona`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoBuscarporzonaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoBuscarporzonaPost(request: ProdutosPorZonaRequest): Observable<HttpResponse<ProdutoEstabelecimentoDTO>> {
    const uri = `/api/produtoestabelecimento/buscarporzona`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProdutoEstabelecimentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoObterprodutosGet
* @param idFamilia 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoObterprodutosGet(idFamilia: string): Observable<HttpResponse<ProdutoVinculoVO[]>> {
    const uri = `/api/produtoestabelecimento/obterprodutos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idFamilia !== undefined && idFamilia !== null) {
      params = params.set('idFamilia', idFamilia + '');
    }
    return this.sendRequest<ProdutoVinculoVO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<ProdutoEstabelecimentoEntity[]>> {
    const uri = `/api/produtoestabelecimento/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<ProdutoEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoObterprodutosvinculadosGet
* @param idFamilia 
* @param idBandeira 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoObterprodutosvinculadosGet(idFamilia: string, idBandeira: string): Observable<HttpResponse<ProdutoBase[]>> {
    const uri = `/api/produtoestabelecimento/obterprodutosvinculados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idFamilia !== undefined && idFamilia !== null) {
      params = params.set('idFamilia', idFamilia + '');
    }
    if (idBandeira !== undefined && idBandeira !== null) {
      params = params.set('idBandeira', idBandeira + '');
    }
    return this.sendRequest<ProdutoBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoImportarhorasGet
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoImportarhorasGet(): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/importarhoras`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProdutoestabelecimentoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProdutoestabelecimentoImportarporzonaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiProdutoestabelecimentoImportarporzonaPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/produtoestabelecimento/importarporzona`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiPerguntaRespostaImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiPerguntaRespostaImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/pesquisaforms/importar/pergunta-resposta`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiProfissaoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiProfissaoByIdGet(id: string): Observable<HttpResponse<ProfissaoEntity>> {
    const uri = `/api/profissao/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProfissaoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiProfissaoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProfissaoIncluirPost(entity: ProfissaoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/profissao/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProfissaoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiProfissaoByIdAlterarPost(id: string, entity: ProfissaoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/profissao/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiProfissaoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiProfissaoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/profissao/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiProfissaoListarGet
  * @return Full HTTP response as Observable
  */
  public ApiProfissaoListarGet(): Observable<HttpResponse<ProfissaoEntity[]>> {
    const uri = `/api/profissao/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ProfissaoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiQuestionarioByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioByIdGet(id: string): Observable<HttpResponse<QuestionarioEntity>> {
    const uri = `/api/questionario/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<QuestionarioEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiQuestionarioIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioIncluirPost(request: QuestionarioEntity): Observable<HttpResponse<any>> {
    const uri = `/api/questionario/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiQuestionarioByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioByIdAlterarPost(id: string, request: QuestionarioEntity): Observable<HttpResponse<any>> {
    const uri = `/api/questionario/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiQuestionarioByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/questionario/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiQuestionarioListarParaUsuarioGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioListarParaUsuarioGet(id: string): Observable<HttpResponse<string[]>> {
    const uri = `/api/questionario/listarParaUsuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    return this.sendRequest<string[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiQuestionarioSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<QuestionarioEntity[]>> {
    const uri = `/api/questionario/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<QuestionarioEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiQuestionarioExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioExportarcsvPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/questionario/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiQuestionarioExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioExportarxlsxPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/questionario/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiQuestionarioListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiQuestionarioListarPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<GenericCollectionResponseRoteiroDadosColetadosBase>> {
    const uri = `/api/questionario/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRoteiroDadosColetadosBase>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRedeestabelecimentoGet
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoGet(): Observable<HttpResponse<RedeEstabelecimentoEntity[]>> {
    const uri = `/api/redeestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RedeEstabelecimentoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRedeestabelecimentoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseRedeEstabelecimentoEntity>> {
    const uri = `/api/redeestabelecimento/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRedeEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRedeestabelecimentoConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseRedeEstabelecimentoEntity>> {
    const uri = `/api/redeestabelecimento/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRedeEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRedeestabelecimentoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoByIdGet(id: string): Observable<HttpResponse<RedeEstabelecimentoEntity>> {
    const uri = `/api/redeestabelecimento/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RedeEstabelecimentoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRedeestabelecimentoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoIncluirPost(entity: RedeEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiRedeestabelecimentoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoByIdAlterarPost(id: string, entity: RedeEstabelecimentoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiRedeestabelecimentoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRedeestabelecimentoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRedeestabelecimentoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRedeestabelecimentoListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseRedeEstabelecimentoEntity>> {
    const uri = `/api/redeestabelecimento/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRedeEstabelecimentoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRedeestabelecimentoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRedeestabelecimentoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/redeestabelecimento/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoSyncGet
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoSyncGet(request: string): Observable<HttpResponse<RegistroDePontoValidatorEntity>> {
    const uri = `/api/registrodeponto/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (request !== undefined && request !== null) {
      params = params.set('request', request + '');
    }
    return this.sendRequest<RegistroDePontoValidatorEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRegistrodepontoSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoSyncPost(lista: RegistroDePontoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiRegistrodepontoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarcsvPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarxlsxPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarcsvgpsPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarcsvgpsPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarcsvgps`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarxlsxgpsPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarxlsxgpsPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarxlsxgps`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarcsvlinhaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarcsvlinhaPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarcsvlinha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarxlsxlinhaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarxlsxlinhaPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarxlsxlinha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

    /**
  * Method ApiRegistrodepontoExportarxlsxlinhaPost
* @param request 
  * @return Full HTTP response as Observable
  */
    public ApiRegistrodepontoExportarpdflinhaPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
      const uri = `/api/registrodeponto/exportarpdflinha`;
      let headers = new HttpHeaders();
      let params = new HttpParams();
      return this.download('post', uri, headers, params, JSON.stringify(request));
    }

  /**
  * Method ApiRegistrodepontoListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoListarPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<GenericCollectionResponseRegistroDePontoAgrupadoDTO>> {
    const uri = `/api/registrodeponto/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRegistroDePontoAgrupadoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoListarsinteticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoListarsinteticoPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<GenericCollectionResponseRegistroDePontoSinteticoDTO>> {
    const uri = `/api/registrodeponto/listarsintetico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRegistroDePontoSinteticoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarxlsxsinteticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarxlsxsinteticoPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarxlsxsintetico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoExportarcsvsinteticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarcsvsinteticoPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarcsvsintetico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoAbonargeralByAnoByMesByDiaGet
* @param ano 
* @param mes 
* @param dia 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoAbonargeralByAnoByMesByDiaGet(ano: number, mes: number, dia: number): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/abonargeral/${ano}/${mes}/${dia}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRegistrodepontoAbonardescontarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoAbonardescontarPost(request: AbonarDescontarRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/abonardescontar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoCancelarabonodescontoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoCancelarabonodescontoPost(request: CancelarAbonoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/cancelarabonodesconto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoAbonarperiodoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoAbonarperiodoPost(request: AbonarPeriodoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/abonarperiodo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistrodepontoCorrigirpontosGet
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoCorrigirpontosGet(): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/corrigirpontos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRegistrodepontoExportarxlsxpontoroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistrodepontoExportarxlsxpontoroboPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registrodeponto/exportarxlsxpontorobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistropontoSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiRegistropontoSyncPost(lista: RegistroPontoUsuarioEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/registroponto/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiRegistropontoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistropontoPost(request: ListarRegistrosPontoRequest): Observable<HttpResponse<GenericCollectionResponseRegistroPontoUsuarioEntity>> {
    const uri = `/api/registroponto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRegistroPontoUsuarioEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistropontoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistropontoExportarcsvPost(request: ListarRegistrosPontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registroponto/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRegistropontoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRegistropontoExportarxlsxPost(request: ListarRegistrosPontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/registroponto/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriopowerbiCsvGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriopowerbiCsvGet(): Observable<HttpResponse<any>> {
    const uri = `/api/relatoriopowerbi/csv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriopowerbiGerarflvGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriopowerbiGerarflvGet(): Observable<HttpResponse<any>> {
    const uri = `/api/relatoriopowerbi/gerarflv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosListaravaliacaofotosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListaravaliacaofotosPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseResumoAvaliacaoFotosDTO>> {
    const uri = `/api/relatorios/listaravaliacaofotos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoAvaliacaoFotosDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportaravaliacaofotosroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportaravaliacaofotosroboPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<ResumoAvaliacaoFotosDTO[]>> {
    const uri = `/api/relatorios/exportaravaliacaofotosrobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoAvaliacaoFotosDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvravaliacaofotosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvravaliacaofotosPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvravaliacaofotos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxravaliacaofotosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxravaliacaofotosPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxravaliacaofotos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListaravaliacaofotosgeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListaravaliacaofotosgeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseResumoAvaliacaoFotosGeralDTO>> {
    const uri = `/api/relatorios/listaravaliacaofotosgeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoAvaliacaoFotosGeralDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportaravaliacaofotosgeralroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportaravaliacaofotosgeralroboPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<ResumoAvaliacaoFotosGeralDTO[]>> {
    const uri = `/api/relatorios/exportaravaliacaofotosgeralrobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoAvaliacaoFotosGeralDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvavaliacaofotosgeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvavaliacaofotosgeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvavaliacaofotosgeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxavaliacaofotosgeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxavaliacaofotosgeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxavaliacaofotosgeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvmatrizPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvmatrizPost(request: ExportarMatrizRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvmatriz`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosMatrizPost
* @param Data 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosMatrizPost(Data: string): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/matriz`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(Data));
  }

  /**
  * Method ApiRelatoriosExportarxlsxmatrizPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxmatrizPost(request: ExportarMatrizRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxmatriz`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxmatrizroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxmatrizroboPost(request: ExportarMatrizRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxmatrizrobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosIncluirrelatoriofilaPost
* @param request 
* @param tipo 
* @param idUsuarioRequest 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosIncluirrelatoriofilaPost(request: string, tipo: string, idUsuarioRequest: string): Observable<HttpResponse<DownloadResponse>> {
    const uri = `/api/relatorios/incluirrelatoriofila`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (tipo !== undefined && tipo !== null) {
      params = params.set('tipo', tipo + '');
    }
    if (idUsuarioRequest !== undefined && idUsuarioRequest !== null) {
      params = params.set('idUsuarioRequest', idUsuarioRequest + '');
    }
    return this.sendRequest<DownloadResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosLinkroteiroGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosLinkroteiroGet(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/linkroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosJdoishuntercsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosJdoishuntercsvPost(request: ExportarMatrizRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/jdoishuntercsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosJdoishunterxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosJdoishunterxlsxPost(request: ExportarMatrizRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/jdoishunterxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosDescontosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosDescontosPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseDescontoDTO>> {
    const uri = `/api/relatorios/descontos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseDescontoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvdescontosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvdescontosPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvdescontos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxdescontosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxdescontosPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxdescontos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarrupturaerradacsvGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarrupturaerradacsvGet(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarrupturaerradacsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosExportarrupturaerradaxlsxPost
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarrupturaerradaxlsxPost(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarrupturaerradaxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosExportarxlsversaoandroidPost
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsversaoandroidPost(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsversaoandroid`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosExportarcsvversaoandroidPost
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvversaoandroidPost(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvversaoandroid`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosAtendimentoestabelecimentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosAtendimentoestabelecimentoPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseAtendimentoEstabelecimentoDTO>> {
    const uri = `/api/relatorios/atendimentoestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseAtendimentoEstabelecimentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosTempoexecucaomarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosTempoexecucaomarcaPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseTempoExecucaoMarcaDTO>> {
    const uri = `/api/relatorios/tempoexecucaomarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseTempoExecucaoMarcaDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvtempoexecucaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvtempoexecucaoPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvtempoexecucao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxtempoexecucaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxtempoexecucaoPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxtempoexecucao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvtarefasplanejadasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvtarefasplanejadasPost(request: RelatorioTarefasPlanejadasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvtarefasplanejadas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxtarefasplanejadasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxtarefasplanejadasPost(request: RelatorioTarefasPlanejadasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxtarefasplanejadas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListartarefasplanejadasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListartarefasplanejadasPost(request: RelatorioTarefasPlanejadasRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioPlanejadosDTO>> {
    const uri = `/api/relatorios/listartarefasplanejadas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioPlanejadosDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosAnaliseexecucaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosAnaliseexecucaoPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioAnaliseExecucaoDTO>> {
    const uri = `/api/relatorios/analiseexecucao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioAnaliseExecucaoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvanaliseexecucaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvanaliseexecucaoPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvanaliseexecucao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportaranaliseexecucaoroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportaranaliseexecucaoroboPost(request: GenericRelatorioRequest): Observable<HttpResponse<RelatorioAnaliseExecucaoDTO[]>> {
    const uri = `/api/relatorios/exportaranaliseexecucaorobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RelatorioAnaliseExecucaoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxanaliseexecucaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxanaliseexecucaoPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxanaliseexecucao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosAbastecimentomarcasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosAbastecimentomarcasPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioAbastecimentoMarcasDTO>> {
    const uri = `/api/relatorios/abastecimentomarcas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioAbastecimentoMarcasDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosFotosregistropontoatestadoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosFotosregistropontoatestadoPost(request: ListarFotosRequest): Observable<HttpResponse<RelatorioFotoRegistroPontoAtestadoDTO[]>> {
    const uri = `/api/relatorios/fotosregistropontoatestado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RelatorioFotoRegistroPontoAtestadoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvabastecimentomarcasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvabastecimentomarcasPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvabastecimentomarcas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxabastecimentomarcasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxabastecimentomarcasPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxabastecimentomarcas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosHorasextrasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosHorasextrasPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseHorasExtrasDTO>> {
    const uri = `/api/relatorios/horasextras`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseHorasExtrasDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvhorasextrasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvhorasextrasPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvhorasextras`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxhorasextrasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxhorasextrasPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxhorasextras`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosTempodeslocamentobiGet
* @param DataInicial 
* @param DataFinal 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosTempodeslocamentobiGet(DataInicial: string, DataFinal: string): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/tempodeslocamentobi`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataInicial !== undefined && DataInicial !== null) {
      params = params.set('DataInicial', DataInicial + '');
    }
    if (DataFinal !== undefined && DataFinal !== null) {
      params = params.set('DataFinal', DataFinal + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosTempoTrabalhadoDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosTempoTrabalhadoDashGet(): Observable<HttpResponse<ResumoTempoTrabalhadoDashDTO[]>> {
    const uri = `/api/relatorios/tempoTrabalhadoDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoTempoTrabalhadoDashDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosTempoTrabalhadoHojeDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosTempoTrabalhadoHojeDashGet(): Observable<HttpResponse<ResumoTempoTrabalhadoDashDTO>> {
    const uri = `/api/relatorios/tempoTrabalhadoHojeDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoTempoTrabalhadoDashDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosPromotoresonlineDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosPromotoresonlineDashGet(): Observable<HttpResponse<PromotoresOnlineDTO>> {
    const uri = `/api/relatorios/promotoresonlineDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<PromotoresOnlineDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosStatususuariosdashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosStatususuariosdashGet(): Observable<HttpResponse<StatusPromotoresDashDTO>> {
    const uri = `/api/relatorios/statususuariosdash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<StatusPromotoresDashDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosAbastecimentomarcasDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosAbastecimentomarcasDashGet(): Observable<HttpResponse<RelatorioAbastecimentoMarcasDashDTO[]>> {
    const uri = `/api/relatorios/abastecimentomarcasDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RelatorioAbastecimentoMarcasDashDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosAnaliseexecucaoDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosAnaliseexecucaoDashGet(): Observable<HttpResponse<RelatorioAnaliseExecucaoDashDTO[]>> {
    const uri = `/api/relatorios/analiseexecucaoDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RelatorioAnaliseExecucaoDashDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosExportarcsvtempodeslocamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvtempodeslocamentoPost(request: ListarTempoDeslocamentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvtempodeslocamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxtempodeslocamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxtempodeslocamentoPost(request: ListarTempoDeslocamentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxtempodeslocamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListartempodeslocamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListartempodeslocamentoPost(request: ListarTempoDeslocamentoRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioTempoDeslocamentoDTO>> {
    const uri = `/api/relatorios/listartempodeslocamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioTempoDeslocamentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosRoteirodiarioDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosRoteirodiarioDashGet(): Observable<HttpResponse<ResumoDashStatusRoteiroDTO>> {
    const uri = `/api/relatorios/roteirodiarioDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoDashStatusRoteiroDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosFotosnodiaDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosFotosnodiaDashGet(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/fotosnodiaDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosRoteironodiaDashGet
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosRoteironodiaDashGet(): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/roteironodiaDash`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRelatoriosListarversoesPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarversoesPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<GenericCollectionResponseResumoListarVersaoAtualDTO>> {
    const uri = `/api/relatorios/listarversoes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoListarVersaoAtualDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvlistarversoesPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvlistarversoesPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvlistarversoes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxlistarversoesPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxlistarversoesPost(request: ListarRegistroDePontoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxlistarversoes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosPromotoresporliderPost
* @param ids 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosPromotoresporliderPost(ids: string[]): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/relatorios/promotoresporlider`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('post', uri, headers, params, JSON.stringify(ids));
  }

  /**
  * Method ApiRelatoriosListarlojaspormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarlojaspormarcaPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseGenericAnaliseMarcasResponseDTO>> {
    const uri = `/api/relatorios/listarlojaspormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseGenericAnaliseMarcasResponseDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvlojaspormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvlojaspormarcaPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvlojaspormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxlojaspormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxlojaspormarcaPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxlojaspormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarpromotorespormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarpromotorespormarcaPost(request: GenericAnaliseMarcasRequest): Observable<HttpResponse<GenericCollectionResponseGenericAnaliseMarcasResponseDTO>> {
    const uri = `/api/relatorios/listarpromotorespormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseGenericAnaliseMarcasResponseDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvpromotorespormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvpromotorespormarcaPost(request: GenericAnaliseMarcasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvpromotorespormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxpromotorespormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxpromotorespormarcaPost(request: GenericAnaliseMarcasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxpromotorespormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarrelatoriogeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarrelatoriogeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseResumoRelatorioGeralDTO>> {
    const uri = `/api/relatorios/listarrelatoriogeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoRelatorioGeralDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvrelatoriogeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvrelatoriogeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvrelatoriogeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxrelatoriogeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxrelatoriogeralPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxrelatoriogeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarrelatorioperformancePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarrelatorioperformancePost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseResumoRelatorioGeralDTO>> {
    const uri = `/api/relatorios/listarrelatorioperformance`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoRelatorioGeralDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvrelatorioperformancePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvrelatorioperformancePost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvrelatorioperformance`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxrelatorioperformancePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxrelatorioperformancePost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxrelatorioperformance`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarrelatorioperformanceroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarrelatorioperformanceroboPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<ResumoRelatorioGeralDTO[]>> {
    const uri = `/api/relatorios/exportarrelatorioperformancerobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoRelatorioGeralDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarrelatoriomarcasatendidasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarrelatoriomarcasatendidasPost(request: ListarRelatorioMarcasAtendidasRequest): Observable<HttpResponse<GenericCollectionResponseResumoRelatorioMarcasAtendidasDTO>> {
    const uri = `/api/relatorios/listarrelatoriomarcasatendidas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoRelatorioMarcasAtendidasDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvrelatoriomarcasatendidasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvrelatoriomarcasatendidasPost(request: ListarRelatorioMarcasAtendidasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvrelatoriomarcasatendidas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxrelatoriomarcasatendidasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxrelatoriomarcasatendidasPost(request: ListarRelatorioMarcasAtendidasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxrelatoriomarcasatendidas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarsemroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarsemroteiroPost(request: GenericSemRoteiroRequest): Observable<HttpResponse<GenericCollectionResponseGenericSemRoteiroResponse>> {
    const uri = `/api/relatorios/listarsemroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseGenericSemRoteiroResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvsemroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvsemroteiroPost(request: GenericSemRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvsemroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxsemroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxsemroteiroPost(request: GenericSemRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxsemroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListarperformancemarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListarperformancemarcaPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<GenericCollectionResponseResumoPerformanceMarcaDTO>> {
    const uri = `/api/relatorios/listarperformancemarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoPerformanceMarcaDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarperformancemarcaroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarperformancemarcaroboPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<ResumoPerformanceMarcaDTO[]>> {
    const uri = `/api/relatorios/exportarperformancemarcarobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ResumoPerformanceMarcaDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvrperformancemarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvrperformancemarcaPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvrperformancemarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxrperformancemarcapromoactionPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxrperformancemarcapromoactionPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxrperformancemarcapromoaction`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvrperformancemarcapromoactionPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvrperformancemarcapromoactionPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvrperformancemarcapromoaction`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxrperformancemarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxrperformancemarcaPost(request: ListarRelatorioGeralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxrperformancemarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListaratrasadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListaratrasadosPost(request: ListarAtrasadosRequest): Observable<HttpResponse<GenericCollectionResponseResumoAtrasadoDTO>> {
    const uri = `/api/relatorios/listaratrasados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoAtrasadoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListaratrasadosperiodoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListaratrasadosperiodoPost(request: ListarAtrasadosPeriodoRequest): Observable<HttpResponse<GenericCollectionResponseResumoAtrasadoDTO>> {
    const uri = `/api/relatorios/listaratrasadosperiodo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoAtrasadoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosListaratrasadosexpressPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosListaratrasadosexpressPost(request: ListarAtrasadosRequest): Observable<HttpResponse<GenericCollectionResponseResumoAtrasadoDTO>> {
    const uri = `/api/relatorios/listaratrasadosexpress`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseResumoAtrasadoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvratrasadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvratrasadosPost(request: ListarAtrasadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvratrasados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxratrasadosperiodoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxratrasadosperiodoPost(request: ListarAtrasadosPeriodoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxratrasadosperiodo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvratrasadosperiodoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvratrasadosperiodoPost(request: ListarAtrasadosPeriodoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvratrasadosperiodo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxratrasadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxratrasadosPost(request: ListarAtrasadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxratrasados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarcsvratrasadosexpressPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarcsvratrasadosexpressPost(request: ListarAtrasadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarcsvratrasadosexpress`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRelatoriosExportarxlsxratrasadosexpressPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRelatoriosExportarxlsxratrasadosexpressPost(request: ListarAtrasadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/relatorios/exportarxlsxratrasadosexpress`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroLatlgtestabelecimentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroLatlgtestabelecimentoPost(request: RoteiroInteligenteEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/latlgtestabelecimento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroImportadorgeralPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroImportadorgeralPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/importadorgeral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroIncluirdocsorcamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroIncluirdocsorcamentoPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/incluirdocsorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroListarorcamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroListarorcamentoPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseOrcamentoRoteiroAutomaticoEntity>> {
    const uri = `/api/roteiro/listarorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseOrcamentoRoteiroAutomaticoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroObterorcamentoPost
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroObterorcamentoPost(): Observable<HttpResponse<OrcamentoRoteiroAutomaticoEntity[]>> {
    const uri = `/api/roteiro/obterorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<OrcamentoRoteiroAutomaticoEntity[]>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroByIdObterorcamentoporidPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroByIdObterorcamentoporidPost(id: string): Observable<HttpResponse<OrcamentoRoteiroAutomaticoEntity>> {
    const uri = `/api/roteiro/${id}/obterorcamentoporid`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<OrcamentoRoteiroAutomaticoEntity>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroByIdExcluirorcamentoPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroByIdExcluirorcamentoPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/${id}/excluirorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroExisteByIdEstabelecimentoByIdFamiliaByDataInicialGet
* @param idEstabelecimento 
* @param idFamilia 
* @param dataInicial 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExisteByIdEstabelecimentoByIdFamiliaByDataInicialGet(idEstabelecimento: string, idFamilia: string, dataInicial: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/existe/${idEstabelecimento}/${idFamilia}/${dataInicial}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroDiasemanaquinzenalPost
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDiasemanaquinzenalPost(): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/diasemanaquinzenal`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroRoteirosparaatualizaremmassaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRoteirosparaatualizaremmassaPost(request: ObterRoteirosAlterarEmMassaRequest): Observable<HttpResponse<string[]>> {
    const uri = `/api/roteiro/roteirosparaatualizaremmassa`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<string[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroAlterarroteiroemmassaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroAlterarroteiroemmassaPost(request: AlterarRoteiroEmMassaRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/alterarroteiroemmassa`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroFlutterGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroFlutterGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroUploadplanilhaPost
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroUploadplanilhaPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/roteiro/uploadplanilha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroImportarPost(request: ImportarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroCalendarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroCalendarioPost(request: ListarCalendarioRoteiroPlanejadoRequest): Observable<HttpResponse<CalendarioRoteiroPlanejadoDTO[]>> {
    const uri = `/api/roteiro/calendario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<CalendarioRoteiroPlanejadoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroGet
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroGet(): Observable<HttpResponse<RoteiroPlanejadoEntity[]>> {
    const uri = `/api/roteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroPlanejadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroPlanejadosporidByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroPlanejadosporidByIdGet(id: string): Observable<HttpResponse<RoteiroPlanejadoEntity[]>> {
    const uri = `/api/roteiro/planejadosporid/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroPlanejadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroByIdGet(id: string): Observable<HttpResponse<RoteiroPlanejadoEntity>> {
    const uri = `/api/roteiro/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroPlanejadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroIncluirPost(request: RoteiroPlanejadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroIncluirlistaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroIncluirlistaPost(request: RoteiroPlanejadoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/incluirlista`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroIncluirlistaroteirointeligentePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroIncluirlistaroteirointeligentePost(request: RoteiroInteligenteRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/incluirlistaroteirointeligente`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroIncluirbulktarefasPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroIncluirbulktarefasPost(request: IncluirBulkTarefasRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/incluirbulktarefas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroByIdAlterarPost(id: string, request: RoteiroPlanejadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<RoteiroPlanejadoEntity[]>> {
    const uri = `/api/roteiro/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<RoteiroPlanejadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroExecutadoSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExecutadoSyncPost(lista: RoteiroExecutadoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/executado/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiRoteiroDadoscoletadosSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosSyncPost(lista: RoteiroDadosColetadosBase[]): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiRoteiroDadoscoletadosArquivoSyncPost
* @param arquivo Upload file
* @param hash 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosArquivoSyncPost(arquivo: FormData, hash: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/arquivo/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (hash !== undefined && hash !== null) {
      params = params.set('hash', hash + '');
    }
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroDadoscoletadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<GenericCollectionResponseRoteiroDadosColetadosBase>> {
    const uri = `/api/roteiro/dadoscoletados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRoteiroDadosColetadosBase>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroTodosdadoscoletadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroTodosdadoscoletadosPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<GenericCollectionResponseRoteiroDadosColetadosBase>> {
    const uri = `/api/roteiro/todosdadoscoletados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRoteiroDadosColetadosBase>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroRupturaedisponivelPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRupturaedisponivelPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioRupEProdDisponivelDTO>> {
    const uri = `/api/roteiro/rupturaedisponivel`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioRupEProdDisponivelDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroRupturaedisponivelBIPost
* @param Data 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRupturaedisponivelBIPost(Data: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/rupturaedisponivelBI`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(Data));
  }

  /**
  * Method ApiRoteiroExportarrupturadisponivelroboPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarrupturadisponivelroboPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<RelatorioRupEProdDisponivelDTO[]>> {
    const uri = `/api/roteiro/exportarrupturadisponivelrobo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RelatorioRupEProdDisponivelDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroRupturaedisponivelExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRupturaedisponivelExportarcsvPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/rupturaedisponivel/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroRupturaedisponivelExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRupturaedisponivelExportarxlsxPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/rupturaedisponivel/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadoscoletadosExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosExportarcsvPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadoscoletadosExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosExportarxlsxPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadoscoletadosExportarPedidoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosExportarPedidoPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/exportarPedido`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroProdutosPesquisaFormsBIPost
* @param Data 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroProdutosPesquisaFormsBIPost(Data: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/ProdutosPesquisaFormsBI`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(Data));
  }

  /**
  * Method ApiRoteiroDataCriticabiPost
* @param Data 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDataCriticabiPost(Data: string): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dataCriticabi`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(Data));
  }

  /**
  * Method ApiRoteiroDadoscoletadosByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosByIdGet(id: string): Observable<HttpResponse<RoteiroDadosColetadosBase>> {
    const uri = `/api/roteiro/dadoscoletados/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroDadosColetadosBase>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroDadoscoletadospesquisaformsByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadospesquisaformsByIdGet(id: string): Observable<HttpResponse<RoteiroDadosPesquisaFormsEntity>> {
    const uri = `/api/roteiro/dadoscoletadospesquisaforms/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroDadosPesquisaFormsEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroDadoscoletadosatualizacaocadastrasByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosatualizacaocadastrasByIdGet(id: string): Observable<HttpResponse<AtualizacaoCadastralDadoColetadoEntity>> {
    const uri = `/api/roteiro/dadoscoletadosatualizacaocadastras/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<AtualizacaoCadastralDadoColetadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroAlterardadoscoletadosPost
* @param dadosColetados 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroAlterardadoscoletadosPost(dadosColetados: RoteiroDadosColetadosBase): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/alterardadoscoletados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(dadosColetados));
  }

  /**
  * Method ApiRoteiroDadoscomplementaresGet
* @param strTipoTarefa 
* @param idFamiliaProdutoAlvo 
* @param idProdutoAlvo 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscomplementaresGet(strTipoTarefa: string, idFamiliaProdutoAlvo: string, idProdutoAlvo: string): Observable<HttpResponse<RoteiroDadosComplementaresBase>> {
    const uri = `/api/roteiro/dadoscomplementares`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (strTipoTarefa !== undefined && strTipoTarefa !== null) {
      params = params.set('strTipoTarefa', strTipoTarefa + '');
    }
    if (idFamiliaProdutoAlvo !== undefined && idFamiliaProdutoAlvo !== null) {
      params = params.set('idFamiliaProdutoAlvo', idFamiliaProdutoAlvo + '');
    }
    if (idProdutoAlvo !== undefined && idProdutoAlvo !== null) {
      params = params.set('idProdutoAlvo', idProdutoAlvo + '');
    }
    return this.sendRequest<RoteiroDadosComplementaresBase>('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroListarfinalizaroperacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroListarfinalizaroperacaoPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<GenericCollectionResponseFinalizarRoteirosResponse>> {
    const uri = `/api/roteiro/listarfinalizaroperacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFinalizarRoteirosResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarcsvfinalizaroperacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarcsvfinalizaroperacaoPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarcsvfinalizaroperacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarxlsxfinalizaroperacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarxlsxfinalizaroperacaoPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarxlsxfinalizaroperacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroListarfinalizarroteiropromotorPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroListarfinalizarroteiropromotorPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<GenericCollectionResponseFinalizarRoteirosResponse>> {
    const uri = `/api/roteiro/listarfinalizarroteiropromotor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFinalizarRoteirosResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarcsvfinalizarroteiropromotorPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarcsvfinalizarroteiropromotorPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarcsvfinalizarroteiropromotor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarxlsxfinalizarroteiropromotorPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarxlsxfinalizarroteiropromotorPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarxlsxfinalizarroteiropromotor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroListarplanejadosparatransferenciaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroListarplanejadosparatransferenciaPost(request: TransferenciaRoteiroRequest): Observable<HttpResponse<RoteiroPlanejadoEntity[]>> {
    const uri = `/api/roteiro/listarplanejadosparatransferencia`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<RoteiroPlanejadoEntity[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroTransferirroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroTransferirroteiroPost(request: TransferenciaRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/transferirroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarcsvtransferenciaroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarcsvtransferenciaroteiroPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarcsvtransferenciaroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroExportarxlsxtransferenciaroteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarxlsxtransferenciaroteiroPost(request: FinalizarRoteiroRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarxlsxtransferenciaroteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroFinalizarmudancausuarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroFinalizarmudancausuarioPost(request: MudancaUsuarioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/finalizarmudancausuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroListardadosepiPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroListardadosepiPost(request: RelatorioEpiRequest): Observable<HttpResponse<GenericCollectionResponseRelatorioEPIDTO>> {
    const uri = `/api/roteiro/listardadosepi`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseRelatorioEPIDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadosepiExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadosepiExportarcsvPost(request: RelatorioEpiRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadosepi/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadosepiExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadosepiExportarxlsxPost(request: RelatorioEpiRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadosepi/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroLimparprodutosExportarcsvGet
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroLimparprodutosExportarcsvGet(): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/limparprodutos/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroLimparprodutosExportarxlsxGet
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroLimparprodutosExportarxlsxGet(): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/limparprodutos/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('get', uri, headers, params, null);
  }

  /**
  * Method ApiRoteiroDadoscoletadosExportarpesquisapersonalizadaxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosExportarpesquisapersonalizadaxlsxPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/exportarpesquisapersonalizadaxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroDadoscoletadosExportarpesquisapersonalizadaumaplanilhaxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroDadoscoletadosExportarpesquisapersonalizadaumaplanilhaxlsxPost(request: ListarDadosColetadosRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/dadoscoletados/exportarpesquisapersonalizadaumaplanilhaxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroAtribuirroteiroautomaticoPost
* @param roteiro 
* @param longDataInicio 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroAtribuirroteiroautomaticoPost(roteiro: RoteiroPlanejadoAutomaticoEntity, longDataInicio: number): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/atribuirroteiroautomatico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (longDataInicio !== undefined && longDataInicio !== null) {
      params = params.set('longDataInicio', longDataInicio + '');
    }
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(roteiro));
  }

  /**
  * Method ApiRoteiroExportarroteiroautomaticoxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroExportarroteiroautomaticoxlsxPost(request: RoteiroInteligenteEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/exportarroteiroautomaticoxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroOrcamentoroteiroautomaticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroOrcamentoroteiroautomaticoPost(request: OrcamentoRAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/orcamentoroteiroautomatico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroRoteiroautomaticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroRoteiroautomaticoPost(request: RoteiroInteligenteEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/roteiroautomatico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroPesquisarlatlngorcamentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroPesquisarlatlngorcamentoPost(request: OrcamentoRAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/roteiro/pesquisarlatlngorcamento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiRoteiroLocalizacoesroteiroautomaticoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiRoteiroLocalizacoesroteiroautomaticoPost(request: RoteiroInteligenteEstabelecimentoRequest): Observable<HttpResponse<LatLngRoteiroAutomaticoDTO[]>> {
    const uri = `/api/roteiro/localizacoesroteiroautomatico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<LatLngRoteiroAutomaticoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSegmentoprodutoGet
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoGet(): Observable<HttpResponse<SegmentoProdutoEntity[]>> {
    const uri = `/api/segmentoproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SegmentoProdutoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSegmentoprodutoBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseSegmentoProdutoEntity>> {
    const uri = `/api/segmentoproduto/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseSegmentoProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSegmentoprodutoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoByIdGet(id: string): Observable<HttpResponse<SegmentoProdutoEntity>> {
    const uri = `/api/segmentoproduto/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SegmentoProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSegmentoprodutoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoIncluirPost(entity: SegmentoProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/segmentoproduto/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSegmentoprodutoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoByIdAlterarPost(id: string, entity: SegmentoProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/segmentoproduto/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSegmentoprodutoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/segmentoproduto/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiSegmentoprodutoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSegmentoprodutoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/segmentoproduto/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSemvendasPlanejadoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasPlanejadoByIdGet(id: string): Observable<HttpResponse<SemVendasPlanejadoEntity>> {
    const uri = `/api/semvendas/planejado/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SemVendasPlanejadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasExecutadoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExecutadoByIdGet(id: string): Observable<HttpResponse<SemVendasExecutadoEntity>> {
    const uri = `/api/semvendas/executado/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SemVendasExecutadoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasPlanejadoincluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasPlanejadoincluirPost(request: SemVendasPlanejadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/planejadoincluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSemvendasExecutadoincluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExecutadoincluirPost(request: SemVendasExecutadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/executadoincluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSemvendasPlanejadoalterarByIdPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasPlanejadoalterarByIdPost(id: string, request: SemVendasPlanejadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/planejadoalterar/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSemvendasExecutadoalterarByIdPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExecutadoalterarByIdPost(id: string, request: SemVendasExecutadoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/executadoalterar/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSemvendasPlanejadoexcluirByIdPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasPlanejadoexcluirByIdPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/planejadoexcluir/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasExecutadoexcluirByIdPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExecutadoexcluirByIdPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/executadoexcluir/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<SemVendasPlanejadoEntity[]>> {
    const uri = `/api/semvendas/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<SemVendasPlanejadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasDownloadexecutadoGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasDownloadexecutadoGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<SemVendasExecutadoEntity[]>> {
    const uri = `/api/semvendas/downloadexecutado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<SemVendasExecutadoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasExecutadosSyncPost
* @param lista 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExecutadosSyncPost(lista: SemVendasExecutadoEntity[]): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/executados/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(lista));
  }

  /**
  * Method ApiSemvendasListarGet
* @param request 
* @param all 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasListarGet(request: string, all: boolean): Observable<HttpResponse<GenericCollectionResponseSemVendasListarDTO>> {
    const uri = `/api/semvendas/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (request !== undefined && request !== null) {
      params = params.set('request', request + '');
    }
    if (all !== undefined && all !== null) {
      params = params.set('all', all + '');
    }
    return this.sendRequest<GenericCollectionResponseSemVendasListarDTO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasExportarcsvGet
* @param request 
* @param all 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExportarcsvGet(request: string, all: boolean): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (request !== undefined && request !== null) {
      params = params.set('request', request + '');
    }
    if (all !== undefined && all !== null) {
      params = params.set('all', all + '');
    }
    return this.downloadText('get', uri, headers, params, null);
  }

  /**
  * Method ApiSemvendasExportarxlsxGet
* @param request 
* @param all 
  * @return Full HTTP response as Observable
  */
  public ApiSemvendasExportarxlsxGet(request: string, all: boolean): Observable<HttpResponse<any>> {
    const uri = `/api/semvendas/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (request !== undefined && request !== null) {
      params = params.set('request', request + '');
    }
    if (all !== undefined && all !== null) {
      params = params.set('all', all + '');
    }
    return this.download('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalGet
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalGet(): Observable<HttpResponse<SubCanalEntity[]>> {
    const uri = `/api/subcanal`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCanalEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalBaseGet
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalBaseGet(): Observable<HttpResponse<SubCanalBase[]>> {
    const uri = `/api/subcanal/base`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCanalBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseSubCanalEntity>> {
    const uri = `/api/subcanal/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseSubCanalEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseSubCanalEntity>> {
    const uri = `/api/subcanal/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseSubCanalEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalByIdGet(id: string): Observable<HttpResponse<SubCanalEntity>> {
    const uri = `/api/subcanal/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCanalEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalBandeiraByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalBandeiraByIdGet(id: string): Observable<HttpResponse<SubCanalEntity[]>> {
    const uri = `/api/subcanal/bandeira/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCanalEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalIncluirPost(entity: SubCanalEntity): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSubcanalByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalByIdAlterarPost(id: string, entity: SubCanalEntity): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSubcanalByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiSubcanalExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcanalExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcanalListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseSubCanalEntity>> {
    const uri = `/api/subcanal/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseSubCanalEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcanalImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcanal/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcanalZonaByZonaNomesGet
* @param zona 
  * @return Full HTTP response as Observable
  */
  public ApiSubcanalZonaByZonaNomesGet(zona: string): Observable<HttpResponse<SubCanalBase[]>> {
    const uri = `/api/subcanal/zona/${zona}/nomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCanalBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcategoriaprodutoGet
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoGet(): Observable<HttpResponse<SubCategoriaProdutoEntity[]>> {
    const uri = `/api/subcategoriaproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCategoriaProdutoEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcategoriaprodutoExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcategoriaprodutoExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcategoriaprodutoListarcategorioprodutoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoListarcategorioprodutoPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseSubCategoriaProdutoEntity>> {
    const uri = `/api/subcategoriaproduto/listarcategorioproduto`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseSubCategoriaProdutoEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcategoriaprodutoByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoByIdGet(id: string): Observable<HttpResponse<SubCategoriaProdutoEntity>> {
    const uri = `/api/subcategoriaproduto/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCategoriaProdutoEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcategoriaprodutoIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoIncluirPost(entity: SubCategoriaProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSubcategoriaprodutoByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoByIdAlterarPost(id: string, entity: SubCategoriaProdutoEntity): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiSubcategoriaprodutoByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiSubcategoriaprodutoImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subcategoriaproduto/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiSubcategoriaprodutoListarByListarExcluidosGet
* @param listarExcluidos 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoListarByListarExcluidosGet(listarExcluidos: boolean): Observable<HttpResponse<SubCategoriaProdutoDTO[]>> {
    const uri = `/api/subcategoriaproduto/listar/${listarExcluidos}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCategoriaProdutoDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubcategoriaprodutoPorfamiliaByIdFamiliaByListarExcluidosGet
* @param idFamilia 
* @param listarExcluidos 
  * @return Full HTTP response as Observable
  */
  public ApiSubcategoriaprodutoPorfamiliaByIdFamiliaByListarExcluidosGet(idFamilia: string, listarExcluidos: boolean): Observable<HttpResponse<SubCategoriaProdutoDTO[]>> {
    const uri = `/api/subcategoriaproduto/porfamilia/${idFamilia}/${listarExcluidos}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<SubCategoriaProdutoDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiSubirfotoUploadPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiSubirfotoUploadPost(request: SubirFotoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/subirfoto/upload`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineByIdGet(id: string): Observable<HttpResponse<TimelineDTO[]>> {
    const uri = `/api/timeline/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimelineDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiTimelineBateriaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineBateriaPost(request: TimeLineRequest): Observable<HttpResponse<TimeLineBateriaDTO>> {
    const uri = `/api/timeline/bateria`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimeLineBateriaDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineRoteiroPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineRoteiroPost(request: TimeLineRequest): Observable<HttpResponse<TimeLineRoteiroDTO[]>> {
    const uri = `/api/timeline/roteiro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimeLineRoteiroDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineLocalizacaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineLocalizacaoPost(request: TimeLineRequest): Observable<HttpResponse<TimeLineLocalizacaoDTO[]>> {
    const uri = `/api/timeline/localizacao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimeLineLocalizacaoDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineDadosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineDadosPost(request: TimeLineRequest): Observable<HttpResponse<TimeLineDadosDTO>> {
    const uri = `/api/timeline/dados`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimeLineDadosDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineListarPost(request: TimeLineRequest): Observable<HttpResponse<TimeLineListarDTO[]>> {
    const uri = `/api/timeline/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<TimeLineListarDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiTimelineRoteiroFlutterPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiTimelineRoteiroFlutterPost(request: TimeLineRequest): Observable<HttpResponse<any>> {
    const uri = `/api/timeline/roteiro/flutter`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUnidadenegocioproprietarioGet
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioGet(): Observable<HttpResponse<UnidadeNegocioProprietarioEntity[]>> {
    const uri = `/api/unidadenegocioproprietario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UnidadeNegocioProprietarioEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUnidadenegocioproprietarioBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseUnidadeNegocioProprietarioEntity>> {
    const uri = `/api/unidadenegocioproprietario/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUnidadeNegocioProprietarioEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUnidadenegocioproprietarioByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioByIdGet(id: string): Observable<HttpResponse<UnidadeNegocioProprietarioEntity>> {
    const uri = `/api/unidadenegocioproprietario/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UnidadeNegocioProprietarioEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUnidadenegocioproprietarioIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioIncluirPost(entity: UnidadeNegocioProprietarioEntity): Observable<HttpResponse<any>> {
    const uri = `/api/unidadenegocioproprietario/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiUnidadenegocioproprietarioByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioByIdAlterarPost(id: string, entity: UnidadeNegocioProprietarioEntity): Observable<HttpResponse<any>> {
    const uri = `/api/unidadenegocioproprietario/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiUnidadenegocioproprietarioByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUnidadenegocioproprietarioByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/unidadenegocioproprietario/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroIncluirPost(request: UsuarioCadastroEntity): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariocadastroByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroByIdAlterarPost(id: string, request: UsuarioCadastroEntity): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariocadastroByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroConsultarcandidatosByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroConsultarcandidatosByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseUsuarioCadastroEntity>> {
    const uri = `/api/usuariocadastro/consultarcandidatos/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioCadastroEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariocadastroExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariocadastroListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseUsuarioCadastroEntity>> {
    const uri = `/api/usuariocadastro/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioCadastroEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariocadastroExistecpfByCpfGet
* @param cpf 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroExistecpfByCpfGet(cpf: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/existecpf/${cpf}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroExisteemailByEmailGet
* @param email 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroExisteemailByEmailGet(email: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariocadastro/existeemail/${email}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroGetenumsGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroGetenumsGet(): Observable<HttpResponse<UsuarioCadastroEnumsSync[]>> {
    const uri = `/api/usuariocadastro/getenums`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioCadastroEnumsSync[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariocadastroConsultarcepByCepGet
* @param cep 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariocadastroConsultarcepByCepGet(cep: string): Observable<HttpResponse<EnderecoVO>> {
    const uri = `/api/usuariocadastro/consultarcep/${cep}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<EnderecoVO>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaBySkipByTopBySortingByOrderGet
* @param skip 
* @param top 
* @param sorting 
* @param order 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaBySkipByTopBySortingByOrderGet(skip: number, top: number, sorting: string, order: string): Observable<HttpResponse<GenericCollectionResponseUsuarioSistemaEntity>> {
    const uri = `/api/usuariosistema/${skip}/${top}/${sorting}/${order}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioSistemaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaConsultarByTermoGet
* @param termo 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaConsultarByTermoGet(termo: string): Observable<HttpResponse<GenericCollectionResponseUsuarioSistemaEntity>> {
    const uri = `/api/usuariosistema/consultar/${termo}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioSistemaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaByIdGet(id: string): Observable<HttpResponse<UsuarioSistemaEntity>> {
    const uri = `/api/usuariosistema/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaSubirdocumentoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaSubirdocumentoPost(request: ArquivoVO): Observable<HttpResponse<ArquivoVO>> {
    const uri = `/api/usuariosistema/subirdocumento`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ArquivoVO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaIncluirPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaIncluirPost(request: UsuarioSistemaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaByIdAlterarPost
* @param id 
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaByIdAlterarPost(id: string, request: UsuarioSistemaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaAlterarSenhaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAlterarSenhaPost(request: AlterarSenhaRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/alterarSenha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaGet(): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListartodosGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListartodosGet(): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listartodos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListarsuperioresGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarsuperioresGet(): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listarsuperiores`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListartodosporusuarioGet
* @param id 
* @param todos 
* @param exibirClientes 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListartodosporusuarioGet(id: string, todos: boolean, exibirClientes: boolean): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listartodosporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    if (todos !== undefined && todos !== null) {
      params = params.set('todos', todos + '');
    }
    if (exibirClientes !== undefined && exibirClientes !== null) {
      params = params.set('exibirClientes', exibirClientes + '');
    }
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListarsuperiorporusuarioGet
* @param id 
* @param todos 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarsuperiorporusuarioGet(id: string, todos: boolean): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listarsuperiorporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    if (todos !== undefined && todos !== null) {
      params = params.set('todos', todos + '');
    }
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListartodosexpressporusuarioGet
* @param id 
* @param todos 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListartodosexpressporusuarioGet(id: string, todos: boolean): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listartodosexpressporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.set('id', id + '');
    }
    if (todos !== undefined && todos !== null) {
      params = params.set('todos', todos + '');
    }
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListarmeiGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarmeiGet(): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/listarmei`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaPromotorespeloroteiroporfamiliasPost
* @param ids 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaPromotorespeloroteiroporfamiliasPost(ids: string[]): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/promotorespeloroteiroporfamilias`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('post', uri, headers, params, JSON.stringify(ids));
  }

  /**
  * Method ApiUsuariosistemaPromotorespormarcasPost
* @param ids 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaPromotorespormarcasPost(ids: string[]): Observable<HttpResponse<UsuarioSistemaBase[]>> {
    const uri = `/api/usuariosistema/promotorespormarcas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioSistemaBase[]>('post', uri, headers, params, JSON.stringify(ids));
  }

  /**
  * Method ApiUsuariosistemaLoginexisteByLoginByIdGet
* @param login 
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaLoginexisteByLoginByIdGet(login: string, id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/loginexiste/${login}/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaCpfexisteByCpfByIdGet
* @param cpf 
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaCpfexisteByCpfByIdGet(cpf: string, id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/cpfexiste/${cpf}/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaHabilitartodosGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaHabilitartodosGet(): Observable<HttpResponse<GenericSemRoteiroResponse[]>> {
    const uri = `/api/usuariosistema/habilitartodos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericSemRoteiroResponse[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaRegistrarsessaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaRegistrarsessaoPost(request: RegistrarSessaoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/registrarsessao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaChecarsessaoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaChecarsessaoPost(request: RegistrarSessaoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/checarsessao`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaRegistrarlogoffGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaRegistrarlogoffGet(): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/registrarlogoff`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaAvatarByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAvatarByIdGet(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/avatar/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExportarcsvPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExportarxlsxPost(request: GenericListRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaListarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarPost(request: GenericListRequest): Observable<HttpResponse<GenericCollectionResponseUsuarioSistemaEntity>> {
    const uri = `/api/usuariosistema/listar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioSistemaEntity>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaUploadfichaPost
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaUploadfichaPost(): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/usuariosistema/uploadficha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaIncluirfichaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaIncluirfichaPost(request: IncluirFichaCadastralRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/incluirficha`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaBaixarfichaByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaBaixarfichaByIdGet(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/baixarficha/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaBaixarholeritePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaBaixarholeritePost(request: BaixarHoleriteRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/baixarholerite`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaUploadholeritePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaUploadholeritePost(request: UploadHoleriteRequest): Observable<HttpResponse<UploadFileDTO>> {
    const uri = `/api/usuariosistema/uploadholerite`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UploadFileDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaBaixarinformePost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaBaixarinformePost(request: BaixarInformeRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/baixarinforme`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaBaixarplrPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaBaixarplrPost(request: BaixarInformeRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/baixarplr`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaListartransferenciasubordinadoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListartransferenciasubordinadoPost(request: TransferirSubordinadoRequest): Observable<HttpResponse<GenericCollectionResponseFinalizarRoteirosResponse>> {
    const uri = `/api/usuariosistema/listartransferenciasubordinado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseFinalizarRoteirosResponse>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaAuthPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAuthPost(request: LoginRequest): Observable<HttpResponse<Token>> {
    const uri = `/api/usuariosistema/auth`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<Token>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaAuthappPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAuthappPost(request: LoginRequest): Observable<HttpResponse<Token>> {
    const uri = `/api/usuariosistema/authapp`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<Token>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaListarestabelecimentosporusuarioPost
* @param idUsuario 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarestabelecimentosporusuarioPost(idUsuario: string): Observable<HttpResponse<string[]>> {
    const uri = `/api/usuariosistema/listarestabelecimentosporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (idUsuario !== undefined && idUsuario !== null) {
      params = params.set('idUsuario', idUsuario + '');
    }
    return this.sendRequest<string[]>('post', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaPesquisarestabelecimentosporusuarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaPesquisarestabelecimentosporusuarioPost(request: UsuarioEstabelecimentoRequest): Observable<HttpResponse<GenericCollectionResponseUsuarioEstabelecimentoDTO>> {
    const uri = `/api/usuariosistema/pesquisarestabelecimentosporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioEstabelecimentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaExportarestabelecimentosporusuarioxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExportarestabelecimentosporusuarioxlsxPost(request: UsuarioEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/exportarestabelecimentosporusuarioxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaSyncGet
* @param DataRef 
* @param TamanhoPaginacao 
* @param RegistroAtual 
* @param IdUsuarioSistema 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaSyncGet(DataRef: string, TamanhoPaginacao: number, RegistroAtual: number, IdUsuarioSistema: string): Observable<HttpResponse<UsuarioSistemaEntity[]>> {
    const uri = `/api/usuariosistema/sync`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (DataRef !== undefined && DataRef !== null) {
      params = params.set('DataRef', DataRef + '');
    }
    if (TamanhoPaginacao !== undefined && TamanhoPaginacao !== null) {
      params = params.set('TamanhoPaginacao', TamanhoPaginacao + '');
    }
    if (RegistroAtual !== undefined && RegistroAtual !== null) {
      params = params.set('RegistroAtual', RegistroAtual + '');
    }
    if (IdUsuarioSistema !== undefined && IdUsuarioSistema !== null) {
      params = params.set('IdUsuarioSistema', IdUsuarioSistema + '');
    }
    return this.sendRequest<UsuarioSistemaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaVincularestabelecimentosporusuarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaVincularestabelecimentosporusuarioPost(request: UsuarioEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/vincularestabelecimentosporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaRemovervinculoestabelecimentosporusuarioPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaRemovervinculoestabelecimentosporusuarioPost(request: UsuarioEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/removervinculoestabelecimentosporusuario`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaVincularlojassuperioresGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaVincularlojassuperioresGet(): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/vincularlojassuperiores`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaCorrigirpermissoesGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaCorrigirpermissoesGet(): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/corrigirpermissoes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaTimelineListarusuariosPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaTimelineListarusuariosPost(request: TimelineUsuariosRequest): Observable<HttpResponse<UsuarioTimelineDTO[]>> {
    const uri = `/api/usuariosistema/timeline/listarusuarios`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<UsuarioTimelineDTO[]>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaCancelarcadastroGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaCancelarcadastroGet(): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/cancelarcadastro`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaListarvendedoresGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListarvendedoresGet(): Observable<HttpResponse<VendedorDTO[]>> {
    const uri = `/api/usuariosistema/listarvendedores`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<VendedorDTO[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaCadastrarvendedorPost
* @param vendedor 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaCadastrarvendedorPost(vendedor: VendedorDTO): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/cadastrarvendedor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(vendedor));
  }

  /**
  * Method ApiUsuariosistemaAlterarvendedorPost
* @param vendedor 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAlterarvendedorPost(vendedor: VendedorDTO): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/alterarvendedor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(vendedor));
  }

  /**
  * Method ApiUsuariosistemaExcluirvendedorByIdDelete
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExcluirvendedorByIdDelete(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/excluirvendedor/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('delete', uri, headers, params, null);
  }

  /**
  * Method ApiUsuariosistemaExcluirvinculosvendedoresPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExcluirvinculosvendedoresPost(request: VendedorEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/excluirvinculosvendedores`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaVincularestabelecimentosporvendedorPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaVincularestabelecimentosporvendedorPost(request: VendedorEstabelecimentoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/vincularestabelecimentosporvendedor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaPesquisarestabelecimentosporvendedorPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaPesquisarestabelecimentosporvendedorPost(request: VendedorEstabelecimentoRequest): Observable<HttpResponse<GenericCollectionResponseVendedorEstabelecimentoDTO>> {
    const uri = `/api/usuariosistema/pesquisarestabelecimentosporvendedor`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseVendedorEstabelecimentoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaImportarPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaImportarPost(request: OrcamentoRoteiroAutomaticoRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/importar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaExportaracessosxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExportaracessosxlsxPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/exportaracessosxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaExportaracessoscsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaExportaracessoscsvPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/exportaracessoscsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaListaracessomesPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaListaracessomesPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseUsuarioAtivoDTO>> {
    const uri = `/api/usuariosistema/listaracessomes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseUsuarioAtivoDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiUsuariosistemaAtualizarstatusGet
  * @return Full HTTP response as Observable
  */
  public ApiUsuariosistemaAtualizarstatusGet(): Observable<HttpResponse<any>> {
    const uri = `/api/usuariosistema/atualizarstatus`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('get', uri, headers, params, null);
  }

  /**
  * Method ApiValormarcaGet
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaGet(): Observable<HttpResponse<ValorMarcaEntity[]>> {
    const uri = `/api/valormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ValorMarcaEntity[]>('get', uri, headers, params, null);
  }

  /**
  * Method ApiValormarcaExportarcsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaExportarcsvPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/exportarcsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaExportarxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaExportarxlsxPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/exportarxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaListarvalormarcaPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaListarvalormarcaPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseValorMarcaDTO>> {
    const uri = `/api/valormarca/listarvalormarca`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseValorMarcaDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaExportardemonstrativocsvPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaExportardemonstrativocsvPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/exportardemonstrativocsv`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.downloadText('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaExportardemonstrativoxlsxPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaExportardemonstrativoxlsxPost(request: GenericRelatorioRequest): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/exportardemonstrativoxlsx`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.download('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaDemonstrativoPost
* @param request 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaDemonstrativoPost(request: GenericRelatorioRequest): Observable<HttpResponse<GenericCollectionResponseDemonstrativoExpressDTO>> {
    const uri = `/api/valormarca/demonstrativo`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<GenericCollectionResponseDemonstrativoExpressDTO>('post', uri, headers, params, JSON.stringify(request));
  }

  /**
  * Method ApiValormarcaByIdGet
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaByIdGet(id: string): Observable<HttpResponse<ValorMarcaEntity>> {
    const uri = `/api/valormarca/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<ValorMarcaEntity>('get', uri, headers, params, null);
  }

  /**
  * Method ApiValormarcaIncluirPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaIncluirPost(entity: ValorMarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/incluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiValormarcaIncluirunicoPost
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaIncluirunicoPost(entity: ValorMarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/incluirunico`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiValormarcaByIdAlterarPost
* @param id 
* @param entity 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaByIdAlterarPost(id: string, entity: ValorMarcaEntity): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/${id}/alterar`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, JSON.stringify(entity));
  }

  /**
  * Method ApiValormarcaByIdExcluirPost
* @param id 
  * @return Full HTTP response as Observable
  */
  public ApiValormarcaByIdExcluirPost(id: string): Observable<HttpResponse<any>> {
    const uri = `/api/valormarca/${id}/excluir`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<any>('post', uri, headers, params, null);
  }

    private sendRequest<T>
      (method: string, uri: string, headers: HttpHeaders, params: HttpParams, body: any): Observable<HttpResponse<T>> {
    if (method === 'get') {
      return this.http.get<T>(this.domain + uri, { headers: headers.set('Accept', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'put') {
      return this.http.put<T>(this.domain + uri, body, { headers: headers.set('Content-Type', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'post') {
      return this.http.post<T>(this.domain + uri, body, { headers: headers.set('Content-Type', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'delete') {
      return this.http.delete<T>(this.domain + uri, { headers: headers, params: params, observe: 'response' });
    } else {
      console.error('Unsupported request: ' + method);
      return Observable.throw('Unsupported request: ' + method);
    }
  }

  private download(method: string, uri: string, headers: HttpHeaders, params: HttpParams, body: any): Observable<HttpResponse<Blob>
  > {
  if (method == "get") {
  return this.http.get(this.domain + uri, { headers: headers.set("Accept", "application/octet-stream").set('Content-Type', 'application/json'), responseType: 'blob', params: params, observe: 'response' });
  } else if (method == "post") {
  return this.http.post(this.domain + uri, body, { headers: headers.set("Accept", "application/octet-stream").set('Content-Type', 'application/json'), responseType: 'blob', params: params, observe: 'response' });
  } else {
  console.error('Unsupported request: ' + method);
  return Observable.throw('Unsupported request: ' + method);
  }
  }

  private downloadText(method: string, uri: string, headers: HttpHeaders, params: HttpParams, body: any): Observable<HttpResponse<string>> {
    if (method == "get") {
      return this.http.get(this.domain + uri, { headers: headers.set('Accept', 'application/json'), responseType: 'text', params: params, observe: 'response' });
    } else if (method == "post") {
      return this.http.post(this.domain + uri, body, { headers: headers.set('Content-Type', 'application/json'), responseType: 'text', params: params, observe: 'response' });
    } else {
      console.error('Unsupported request: ' + method);
      return Observable.throw('Unsupported request: ' + method);
    }
  }
}
