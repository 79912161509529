import {
  HashLocationStrategy,
  LocationStrategy,
  DecimalPipe,
  DatePipe,
} from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  APP_INITIALIZER,
  Injectable,
  Injector,
  LOCALE_ID,
  isDevMode,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiInterceptor } from "./shared/interceptors/api-interceptor";
import { LoaderInterceptor } from "./shared/interceptors/loader/loader-interceptor";
import { LoaderService } from "./shared/interceptors/loader/loader.service";
import { LoaderComponent } from "./shared/interceptors/loader/loader.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { AppRouting } from "./app.routing";
import { ApiClientService } from "./services-generated/index";
import { RoteiroModule } from "./roteiro/roteiro.module";
import { TimelineModule } from "./timeline/timeline.module";
import { SharedService } from "./shared/services/shared.service";

import { registerLocaleData } from "@angular/common";
import ptBr from "@angular/common/locales/pt";
registerLocaleData(ptBr, "pt");

import { defineLocale } from "ngx-bootstrap/chronos";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { Configuration } from "./configuration";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
defineLocale("pt-br", ptBrLocale);

import { SharedModule } from "./shared/shared.module";
import { EngDynamicPipe } from "./shared/eng-pipe/eng-dynamic.pipe";
import { EngSimNaoPipe } from "./shared/eng-pipe/eng-sim-nao.pipe";
import { NgxMaskModule } from "ngx-mask";
import { EngTimespan } from "./shared/eng-pipe/eng-timespan.pipe";
import { ValidadorDocService } from "./services/validador-doc.service";
import { DsAtivoInativoPipe } from "./shared/eng-pipe/ds-ativo-inativo.pipe";
import { SortableModule } from "ngx-bootstrap";
import { PesquisaFormsPipe } from "./shared/eng-pipe/pesquisa-forms.pipe";
import { StatusRoteiroExecEnumPipe } from "./shared/eng-pipe/status-roteiro-exec-enum.pipe";
import { OverlayModule } from "@angular/cdk/overlay";
import { AuthModule } from "./auth/auth.module";
import { JwtModule } from "@auth0/angular-jwt";
import { BrowserXhr } from "@angular/common/http/src/xhr";
import { CandidatosComponent } from "./usuario-sistema/candidatos/candidatos.component";
import { WarningSnackbarComponent } from "./snackBar/atetionSnackBar/warning-snackbar.component";

import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { MatSnackBarModule } from "@angular/material";
import { SecToTimePipe } from "./shared/eng-pipe/sec-to-time.pipe";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { MatIconModule } from '@angular/material/icon';

export function tokenGetter() {
  return localStorage.getItem("userToken");
}

export function obterUrlApi() {
  let assinante = obterAssinante();
  if (assinante != "" && assinante != undefined) {
    if (assinante == "localhost") {
      return 'http://localhost:5004'
    }

    // if (window.location.hostname == "85.209.93.129")
    //   return 'http://85.209.93.129:5004'

    if (assinante == 'bulby')
       return window.location.protocol + "//api.bulby.com.br"

    return (
      window.location.protocol + "//api-" + assinante + ".bulby.com.br"
    );
  } else {
    throw "Falha ao obter assinante!";
  }
}

export function obterAssinante() {
  let assinante = window.location.hostname.split(".")[0];
  return assinante = assinante.replace("api-", "");
}

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    MatSnackBarModule,
    AppRouting,
    AuthModule,
    NgxMaskModule.forRoot(),
    SortableModule.forRoot(),
    OverlayModule,
    MatIconModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost", obterUrlApi()],
      },
    }),
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "pt",
    },
    Configuration,
    ApiClientService,
    SharedService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    DecimalPipe,
    DatePipe,
    EngDynamicPipe,
    EngSimNaoPipe,
    EngTimespan,
    LoaderService,
    ValidadorDocService,
    DsAtivoInativoPipe,
    PesquisaFormsPipe,
    StatusRoteiroExecEnumPipe,
    SecToTimePipe,
    BsModalService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector, configuration: Configuration) {
    AppModule.injector = injector;
    configuration.Server = obterUrlApi();
    configuration.Assinante = obterAssinante();
  }
}
