import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ApiClientService} from "../../services-generated";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  erro = "";
  showPassword: boolean = true;
  @ViewChild('snackBar') snackBar: ElementRef;
  mensagemErro = "Usuário ou Senha incorreto";

  constructor(private api: ApiClientService,
              private router: Router,) { }

  ngOnInit() {
    this.form = new FormGroup({
      usuario: new FormControl(''),
      senha: new FormControl('')
    });
  }

  ngAfterViewInit() {
    // Aqui garantimos que o ViewChild já foi inicializado
  }

  temErro(): boolean {
    return this.erro !== ''
        && this.erro !== null
        && this.erro !== undefined;
  }

  public toogleShowPassword(): void{
    const inputPassword = document.querySelector("#inputSenha");
    const inputImage = document.querySelector("#inputSenha + label > img");
    if (this.showPassword) {
      inputPassword.setAttribute('type', 'text');
      if (inputImage) {
        inputImage.setAttribute('src', '../../../assets/images/icons/senha-mostrar.png');
      }
    } else {
      inputPassword.setAttribute('type', 'password');
      if (inputImage) {
        inputImage.setAttribute('src', '../../../assets/images/icons/senha-esconder.png');
      }
    }
    this.showPassword = !this.showPassword;
  }

  private showSnackBar():void{
    if (!this.snackBar) {
      console.warn('SnackBar não foi inicializado');
      return;
    }
    
    this.snackBar.nativeElement.classList.add("showSnackBar");
    setTimeout(()=> {
      if (this.snackBar && this.snackBar.nativeElement) {
        this.snackBar.nativeElement.classList.remove("showSnackBar");
      }
    }, 5000);
  }

  public removeSnackBar():void {
    if (this.snackBar && this.snackBar.nativeElement) {
      this.snackBar.nativeElement.classList.remove("showSnackBar");
    }
  }

  login() {
    if (this.form.invalid) {
      return;
    }

    this.api.ApiUsuariosistemaAuthPost(this.form.value).subscribe(
      res => {
        if (res.body.Authenticated) {
          this.erro = "";
          localStorage.setItem("userToken", res.body.AccessToken);
          localStorage.setItem("userData", JSON.stringify(res.body));
          this.router.navigate(['/home']);
        } else {
          this.mensagemErro = res.body.Erro || "Usuário ou senha inválidos. Verifique se seus dados de acesso estão corretos.";
          this.erro = this.mensagemErro;
          this.showSnackBar();
        }
      },
      error => {
        this.mensagemErro = "Erro ao tentar fazer login. Verifique sua conexão de internet.";
        this.erro = this.mensagemErro;
        this.showSnackBar();
      }
    )
  }
}
